// React components
import React from "react";
import { Link } from "react-router-dom";

// mui components
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import InfoIcon from "@mui/icons-material/Info";

// components
import ParallaxBlock from "./component-parallax-block";
import { code } from "./utilities";
import Bio from "./component-bio";
import { bios } from "./data-bios";

export default function HomePage(props) {

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        The IUROPA <br/> CJEU Database
      </div>
    </div>

  return (
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-7.png" content={parallax} offset={-250} />
      <div className="block block-shaded">
        <div className="title-large">
          CJEU Text Corpus
        </div>
      </div>
      <div className="block">
        <div className="tool-message-container">
          <div className="tool-message-box">
            <div className="tool-message-icon-container">
              <InfoIcon className="tool-message-icon" />
            </div>
            <div className="tool-message-text">
              <b>Coming soon!</b> We're planning to release the IUROPA CJEU Text Corpus later this year. Please <a className="link" href="mailto:joshua.fjelstul@arena.uio.no">contact us</a> for more information.
            </div>
          </div>
        </div>
        <div className="title-medium">
          By Michal Ovádek, Joshua Fjelstul, Daniel Naurin and Johan Lindholm
        </div>
        <div className="text">
          The IUROPA Text Corpus is a database of judicial texts from the Court of Justice of the European Union (CJEU). The database comprises all types of judicial decisions from the Court of Justice, the General Court and the Civil Service Tribunal, as well as Advocate-General (AG) opinions. Each document is split into paragraphs to enable more granular analyses. Where available, both the French and English texts are included. The IUROPA Text Corpus is a more complete database of CJEU texts than Curia or Eur-Lex. Check out the <a className="link" href="https://michalovadek.github.io/iuropa-text-corpus">live dashboard</a> for the corpus, created by Michal Ovádek, to learn more.
        </div>
        {/* <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" disabled startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-issues-and-positions-csv.zip" download="IUROPA-CJEU-Database-issues-and-positions-csv.zip">CSV</Button>
          </div>
          <div className="button-container-centered">
            <Button variant="contained" disabled startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-issues-and-positions-RData.zip" download="IUROPA-CJEU-Database-issues-and-positions-RData.zip">RData</Button>
          </div>
        </div> */}
        <div className="title-small">
          Citation
        </div>
        <div className="text">
          If you use data from the IUROPA CJEU Text Corpus, please cite the data using the following citation:
        </div>
        <div className="quote">
          Ovádek, Michal, Joshua Fjelstul, Daniel Naurin and Johan Lindholm. 2023. "The IUROPA CJEU Text Corpus," in Lindholm, Johan, Daniel Naurin, Urška Šadl, Anna Wallerman Ghavanini, Stein Arne Brekke, Joshua Fjelstul, Silje Synnøve Lyder Hermansen, Olof Larsson, Andreas Moberg, Moa Näsström, Michal Ovádek, Tommaso Pavone, and Philipp Schroeder, <span className="italic">The IUROPA Court of Justice of the European Union (CJEU) Database</span>, IUROPA, https://www.iuropa.pol.gu.se/.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="text" disabled startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-national-courts-citation.bib" download="IUROPA-CJEU-Database-national-courts-citation.bib">
              BibTeX
            </Button>
          </div>
        </div>
      </div>
      <div className="block block-shaded">
        <div className="title-large">
          Contributors
        </div>
        <Bio id="ovadek" data={bios.ovadek} />
        <Bio id="fjelstul" data={bios.fjelstul} />
        <Bio id="naurin" data={bios.naurin} />
        <Bio id="lindholm" data={bios.lindholm} />
      </div>
      {/* <div className="block block-shaded">
        <div className="title-large">
          Documentation
        </div>
        <div className="text">
          You can download the codebook for the <span className="bold">Issues and Positions Component</span> as a <span className="code">.pdf</span>. The codebook includes a description of each variable, including the values that each value can take, and a detailed explanation about how each variable was coded.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-issues-and-positions-codebook.pdf" download="IUROPA-CJEU-Database-issues-and-positions-codebook.pdf">Codebook</Button>
          </div>
        </div>
        <div className="text">
          You can also download a report as a <span className="code">.pdf</span> that discusses the results of reliability checks conducted for hand-coded variables. The report shows a high degree of inter-coder reliability.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-issues-and-positions-reliability-report.pdf" download="IUROPA-CJEU-Database-issues-and-positions-reliability-report.pdf">Report</Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
