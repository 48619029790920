// React components
import React, { Component } from 'react'
import { Link } from "react-router-dom";

// mui components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import DownloadIcon from '@mui/icons-material/CloudDownload';

// components
import ParallaxBlock from "./component-parallax-block";
import Bio from "./component-bio";
import { bios } from "./data-bios";

export default function HomePage(props) {

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        The IUROPA <br/> CJEU Database
      </div>
    </div>

  return (
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-7.png" content={parallax} offset={-250} />
      <div className="block block-shaded">
        <div className="title-large">
          Doctrine and Legal Outcomes Component
        </div>
      </div>
      <div className="block">
        <div className="title-medium">
          By Urška Šadl, Lucía López Zurita, Irene Otero, Eun Hye Kim, and Stein Arne Brekke
        </div>
        <div className="text">
          The Doctrine and Legal Outcomes component of the IUROPA CJEU Database seeks a doctrinally sophisticated and empirically accurate understanding of the interaction between the legal outcome of cases and their extra-legal (political) context.
        </div>
        <div className="text">
          In broadest terms, it does this by delving into legal argumentation and interpretation systematically. As opposed to the classic legal analysis as a hermeneutical enterprise, deep coding departs from a pre-established set of legally relevant parameters and a detailed coding protocol. As opposed to the classical political science analysis, it goes deeper. Apart from the outcome of cases and the position of the legal actors, it focuses on the CJEU's doctrinal stance, the strictness of judicial review, the scope of the margin of appreciation, the effect of the outcome on EU's competence etc. Importantly, the analysis records whether the CJEU expands its doctrines, considers that the provision has direct effect, protects the rights of the individuals, the interests of consumers, environment and free movement more generally.
        </div>
        <div className="text">
          By extracting this additional (fine-grained) information from the judgments, we can begin to understand and explore how the CJEU reacts to the political context with legal means and within the constraints of the law. In other words, we can gauge the mechanisms, which let the law get into politics, and politics get into the law, and improve on the research on law and politics in Europe. While the primary aim of deep coding is to contribute to the understanding of judicial authority and long term processes, it can be used to address more urgent research questions of judicial independence (the Portuguese and the Polish judges) as well as politically adverse context (Brexit) or economic crises (the financial crisis).
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-doctrine-and-legal-outcomes.csv" download="IUROPA-CJEU-Database-doctrine-and-legal-outcomes.csv">CSV</Button>
          </div>
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-doctrine-and-legal-outcomes.RData" download="IUROPA-CJEU-Database-doctrine-and-legal-outcomes.RData">RData</Button>
          </div>
        </div>
        <div className="title-small">
          Citation
        </div>
        <div className="text">
          If you use data from the Doctrine and Legal Outcomes component of the IUROPA CJEU Database, please cite the data using the following citation:
        </div>
        <div className="quote">
          Šadl, Urška, Lucía López Zurita, Irene Otero, Eun Hye Kim, and Stein Arne Brekke. 2024. "The IUROPA CJEU Database: Doctrine and Legal Outcomes," in Lindholm, Johan, Daniel Naurin, Urška Šadl, Anna Wallerman Ghavanini, Stein Arne Brekke, Joshua Fjelstul, Silje Synnøve Lyder Hermansen, Olof Larsson, Andreas Moberg, Moa Näsström, Michal Ovádek, Tommaso Pavone, and Philipp Schroeder, <span className="italic">The IUROPA Court of Justice of the European Union (CJEU) Database</span>, IUROPA, https://www.iuropa.pol.gu.se/.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="text" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-doctrine-and-legal-outcomes-citation.bib" download="IUROPA-CJEU-Database-doctrine-and-legal-outcomes-citation.bib">
              BibTeX
            </Button>
          </div>
        </div>
      </div>
      <div className="block block-shaded">
        <div className="title-large">
          Documentation
        </div>
        <div className="text">
          You can download the codebook for the Doctrine and Legal Outcomes component as a <span className="code">.csv</span> file. The codebook includes a description of each variable, including the values that each value can take, and a detailed explanation about how each variable was coded.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-doctrine-and-legal-outcomes-codebook.csv" download="IUROPA-CJEU-Database-doctrine-and-legal-outcomes-codebook.csv">Codebook</Button>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="title-large">
          Contributors
        </div>
        <Bio id="sadl" data={bios.sadl} />
        <Bio id="lopez-zurita" data={bios.lopezZurita} />
        <Bio id="otero" data={bios.otero} />
        <Bio id="kim" data={bios.kim} />
        <Bio id="brekke" data={bios.brekke} />
      </div>
    </div>
  );
};
