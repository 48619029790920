import React, { useRef, useEffect } from "react";
import * as d3 from 'd3';
import * as d3zoom from 'd3-zoom';
import * as d3selection from 'd3-selection';
import { renderToString } from 'react-dom/server'
import styled from "styled-components";
import { Block } from '@material-ui/icons';

const Container = styled.div`
  position: fixed;
  left: 350px;
  right: 0px;
  top: 80px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border-radius: 8px;
  overflow: hidden;
  background-color: white;
  // border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Table = styled.div`
  box-sizing: border-box;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  background-color: #5D92E0;
  border: 2px solid #5D92E0;
  color: white;
`;

const SupplementalTable = styled(Table)`
  background-color: #D0DFF6;
  border: 2px solid #5D92E0;
  color: #5D92E0;
`;

const ComponentTable = styled(Table)`
  background-color: #9D84DF;
  border: 2px solid #9D84DF;
  color: white;
`;

const ComponentSupplementalTable = styled(Table)`
  background-color: #E2DAF5;
  border: 2px solid #9D84DF;
  color: #9D84DF;
`;

const PrimaryKey = styled.div`
  box-sizing: border-box;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 10px;
  background-color: #5D92E0;
  border: 2px solid #5D92E0;
  color: white;
`;

const ComponentPrimaryKey = styled(PrimaryKey)`
  background-color: #9D84DF;
  border: 2px solid #9D84DF;
  color: white;
`;

const ForeignKey = styled.div`
  box-sizing: border-box;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 10px;
  background-color: #D0DFF6;
  border: 2px solid #5D92E0;
  color: #5D92E0;
`;

const ComponentForeignKey = styled(ForeignKey)`
  background-color: #E2DAF5;
  border: 2px solid #9D84DF;
  color: #9D84DF;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px;
`;

const useD3 = (renderFunction, dependencies) => {
  const ref = useRef();
  useEffect(() => {
    renderFunction(d3selection.select(ref.current));
    return () => { };
  }, dependencies)
  return ref;
}

const makeNodeHTML = (d, selected) => {

  let node;

  if (d.type === "table") {
    node =
      <Table>
        {d.label}
      </Table>
  }

  if (d.type === "component table") {
    node =
      <ComponentTable>
        {d.label}
      </ComponentTable>
  }

  if (d.type === "component supplemental table") {
    node =
      <ComponentSupplementalTable>
        {d.label}
      </ComponentSupplementalTable>
  }

  if (d.type === "supplemental table") {
    node =
      <SupplementalTable>
        {d.label}
      </SupplementalTable>
  }

  if (d.type === "primary key") {
    node =
      <PrimaryKey>
        {d.label}
      </PrimaryKey>
  }

  if (d.type === "foreign key") {
    node =
      <ForeignKey>
        {d.label}
      </ForeignKey>
  }

  if (d.type === "component primary key") {
    node =
      <ComponentPrimaryKey>
        {d.label}
      </ComponentPrimaryKey>
  }

  if (d.type === "component foreign key") {
    node =
      <ComponentForeignKey>
        {d.label}
      </ComponentForeignKey>
  }

  return (
    node
  )
}

const NetworkGraph = (props) => {

  const { linksData, nodesData, selected, callback } = props;

  const nodesRef = useRef(undefined);
  const selectedNode = useRef(selected);

  const renderGraph = (selected) => {

    const containerRect = ref.current.getBoundingClientRect();
    const height = containerRect.height;
    const width = containerRect.width;

    const links = linksData.map((d) => Object.assign({}, d));
    const nodes = nodesData.map((d) => Object.assign({}, d));

    const dragStarted = (event, d) => {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };

    const dragged = (event, d) => {
      d.fx = event.x;
      d.fy = event.y;
      d.fixed = true;
    };

    const dragEnded = (event, d) => {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    };

    const simulation = d3
      .forceSimulation()
      .force("charge", d3.forceManyBody().strength(-800)) // larger negative numbers spread out graph
      .force("center", d3.forceCenter(width / 2, height / 2)) // keeps graph centered
      .force("link", d3.forceLink().distance(d => d.type === "merge" ? 300 : 0).strength(1).id(d => d.id)) // pulls together nodes
      .force("collide", d3.forceCollide().strength(1).radius(90).iterations(1)) // creates collider around nodes

    const zoomContainer = d3
      .select(ref.current)
      .append("div")
      .attr("id", "zoom-container")
      .style("position", "absolute")
      .style("width", "100%")
      .style("height", "100%")

    const svg = zoomContainer
      .append("svg")
      .style("position", "absolute")
      .style("pointer-events", "none")
      .style("width", width)
      .style("height", height)
    // .style("background-color", "rgba(0, 0, 0, 0.02)")

    var glink = svg
      .append("g")
    // .attr("transform", "translate(0, 0) scale(1)")

    var link = glink
      .attr("class", "links")
      .selectAll("links")
      .data(links)
      .enter()
      .append("line")
      .attr("stroke-width", d => d.type === "variable" ? 2 : 1)
      .attr("stroke", d => d.type === "variable" ? d.color : "rgba(0, 0, 0, 0.2)")
      // .attr("stroke", d => d.color)
      .attr("stroke-dasharray", d => d.type === "variable" ? "none" : "12, 8")

    const selectFeature = (d) => {
      zoomContainer
        .transition().duration(500)
        .call(zoom.transform, d3.zoomIdentity.translate(width / 2 - d.x, height / 2 - d.y).scale(1))
    }

    const node = zoomContainer
      .append("div")
      .attr("class", "nodes")
      .style("position", "absolute")
      .selectAll(".node")
      .data(nodes)
      .enter()
      .append("div")
      .attr("class", "node")
      .attr("id", d => d.id)
      .style("position", "absolute")
      // .style("z-index", 8100)
      .style("cursor", "pointer")
      .html(d => renderToString(makeNodeHTML(d, selected)))

    nodesRef.current = node;

    const updateNodes = (selected) => {
      node
        .html(d => renderToString(makeNodeHTML(d, selected)))
    }

    node.call(d3.drag()
      .on("start", dragStarted)
      .on("drag", dragged)
      .on("end", dragEnded));

    const onZoom = (event) => {
      d3.select(".links").attr("transform", "translate(" + event.transform.x + "," + event.transform.y + ") scale(" + event.transform.k + ")");
      d3.select(".nodes").style("transform", "translate(" + event.transform.x + "px," + event.transform.y + "px) scale(" + event.transform.k + ")");
    }

    // function to handle zooming
    var zoom = d3zoom.zoom()
      .scaleExtent([0.2, 3])
      .on("zoom", onZoom)

    // call the zoom function
    zoomContainer.call(zoom)

    const onMouseClick = (event, d) => {
      event.stopPropagation();
      if (d.type === "table" || d.type === "supplemental table" || d.type === "component table" || d.type === "component supplemental table") {
        selectedNode.current = d;
        // callback(d.id)
        updateNodes(d.id)
      }
      // selectFeature(d)
    }

    node
      .on("click", onMouseClick)

    zoomContainer
      .on("click", () => {
        callback(undefined)
        updateNodes(undefined)
      })

    const getWidth = (d) => {
      let width;
      if (d.type === "table") {
        width = 120;
      }
      if (d.type === "supplemental table") {
        width = 120;
      }
      if (d.type === "component table") {
        width = 120;
      }
      if (d.type === "component supplemental table") {
        width = 120;
      }
      if (d.type === "primary key" || d.type === "foreign key" || d.type === "component primary key" || d.type === "component foreign key") {
        width = 150;
      }
      return (width);
    }

    const getHeight = (d) => {
      let height;
      if (d.type === "table") {
        height = 120;
      }
      if (d.type === "supplemental table") {
        height = 120;
      }
      if (d.type === "component table") {
        height = 120;
      }
      if (d.type === "component supplemental table") {
        height = 120;
      }
      if (d.type === "primary key" || d.type === "foreign key" || d.type === "component primary key" || d.type === "component foreign key") {
        height = 30;
      }
      return (height);
    }

    const ticked = () => {

      link
        .attr("x1", d => d.source.x)
        .attr("y1", d => d.source.y)
        .attr("x2", d => d.target.x)
        .attr("y2", d => d.target.y);

      node
        .style("left", d => d.x - (getWidth(d) / 2) + "px")
        .style("top", d => d.y - (getHeight(d) / 2) + "px");
    }

    simulation
      .nodes(nodes)
      .on("tick", ticked)

    simulation
      .force("link")
      .links(links)
  }

  const ref = useD3(
    (selected) => renderGraph(selected),
    []
  )

  useEffect(() => {

    if (nodesRef.current) {
      nodesRef.current
        .html(d => renderToString(makeNodeHTML(d, selected)))
    }

    if (selected) {
      d3.select("#" + selected).dispatch("click");
    }

  }, [selected])

  return (
    <Container ref={ref} />
  );
}

export default NetworkGraph;
