// React components
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export default function Footer (props) {

  const footerColumns =
  <div className="footer-columns">
    <div className="footer-column">
      <div className="footer-item-container">
        <Link className="footer-heading" to="/">The CJEU Database</Link>
      </div>
      <div className="footer-item-container">
        <Link className="footer-item" to="/cjeu-database-platform">CJEU Database Platform</Link>
      </div>
      <div className="footer-item-container">
        <Link className="footer-item" to="/cjeu-text-corpus">CJEU Text Corpus</Link>
      </div>
      <div className="footer-item-container">
        <Link className="footer-item" to="/issues-and-positions">Issues and Positions</Link>
      </div>
      <div className="footer-item-container">
        <Link className="footer-item" to="/noncompliance">Noncompliance</Link>
      </div>
      <div className="footer-item-container">
        <Link className="footer-item" to="/doctrine-and-legal-outcomes">Doctrine and Legal Outcomes</Link>
      </div>
      {/* <div className="footer-item-container">
        <Link className="footer-item" to="/cjeu-database/parties-and-lawyers">Parties and Lawyers</Link>
      </div> */}
      <div className="footer-item-container">
        <Link className="footer-item" to="/national-courts">National Courts</Link>
      </div>
      <div className="footer-break"></div>
    </div>
    <div className="footer-column">
      <div className="footer-item-container">
        <div className="footer-heading" to="/">Research Tools</div>
      </div>
      <div className="footer-item-container">
        <Link className="footer-item" to="/explorer-tool">The IUROPA Explorer Tool</Link>
      </div>
      <div className="footer-item-container">
        <Link className="footer-item" to="/download-tool">The IUROPA Download Tool</Link>
      </div>
      {/* <div className="footer-item-container">
        <Link className="footer-item" to="/cjeu-database/cjeu-database-platform/report-tool">The IUROPA Report Tool</Link>
      </div> */}
      <div className="footer-item-container">
        <Link className="footer-item" to="/#iuropa-r-package">The IUROPA R Package</Link>
      </div>
      <div className="footer-break"></div>
      <div className="footer-item-container">
        <Link className="footer-heading" to="/about">About</Link>
      </div>
    </div>
  </div>;

  return (
    <div className="footer">
      <div className="footer-top">
        <Link className="app-branding-large white" to="/">
          IUROPA
        </Link>
      </div>
      <div className="footer-middle">
        {footerColumns}
      </div>
      <div className="footer-bottom">
        <div className="footer-credit">
          The IUROPA website was designed and programmed by Joshua C. Fjelstul.
        </div>
      </div>
    </div>
  );
}
