// React components
import React from "react";
import { Link } from "react-router-dom";

// mui components
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import InfoIcon from "@mui/icons-material/Info";

// components
import ParallaxBlock from "./component-parallax-block";
import Bio from "./component-bio";
import { bios } from "./data-bios";

export default function HomePage(props) {

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        The IUROPA <br/> CJEU Database
      </div>
    </div>

  return (
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-7.png" content={parallax} offset={-250} />
      <div className="block block-shaded">
        <div className="title-large">
          National Courts Component
        </div>
      </div>
      <div className="block">
        {/* <div className="tool-message-container">
          <div className="tool-message-box">
            <div className="tool-message-icon-container">
              <InfoIcon className="tool-message-icon" />
            </div>
            <div className="tool-message-text">
              <b>Coming soon!</b> We're about to release v2.00.00 of the IUROPA CJEU Database Platform. This version will include the National Courts Component.
            </div>
          </div>
        </div> */}
        <div className="title-medium">
          By Tommaso Pavone, Anna Wallerman Ghavanini, and Stein Arne Brekke
        </div>
        <div className="text">
          The National Courts component of the IUROPA CJEU Database provides detailed information about every national court that has referred a case to the CJEU through the preliminary reference procedure laid down in Article 267 TFEU.
        </div>
        <div className="text">
          National courts have long been heralded as linchpins of the EU judiciary and engines of judicial harmonization. Many have theorized about the drivers that persuade national courts to take up their European mandate, but beyond the most prominent national courts, little information has been available about the CJEU's domestic judicial interlocutors. The purpose of this component is to provide an infrastructure that will allow researchers to gain a better descriptive understanding of the courts that refer cases to the CJEU, and to facilitate empirical analyses of how the varying status and expertise of national courts impinges upon their participation in the process of European integration through law.
        </div>
        <div className="text">
          National Courts in the CJEU Preliminary Reference Procedure combines scraped data with hand-coding and expert evaluations. These data comprise standardized English translations of the courts, their city location, their position in the domestic judicial hierarchy, their competences and specialization, and whether they engage in collegial decision-making.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-national-courts.csv" download="IUROPA-CJEU-Database-national-courts.csv">CSV</Button>
          </div>
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-national-courts.RData" download="IUROPA-CJEU-Database-national-courts.RData">RData</Button>
          </div>
        </div>
        <div className="title-small">
          Citation
        </div>
        <div className="text">
          If you use data from the National Courts component of the IUROPA CJEU Database, please cite the data using the following citation:
        </div>
        <div className="quote">
          Pavone, Tommaso, Anna Wallerman Ghavanini, and Stein Arne Brekke. 2024. "The IUROPA CJEU Database: National Courts," in Lindholm, Johan, Daniel Naurin, Urška Šadl, Anna Wallerman Ghavanini, Stein Arne Brekke, Joshua Fjelstul, Silje Synnøve Lyder Hermansen, Olof Larsson, Andreas Moberg, Moa Näsström, Michal Ovádek, Tommaso Pavone, and Philipp Schroeder, <span className="italic">The IUROPA Court of Justice of the European Union (CJEU) Database</span>, IUROPA, https://www.iuropa.pol.gu.se/.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="text" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-national-courts-citation.bib" download="IUROPA-CJEU-Database-national-courts-citation.bib">
              BibTeX
            </Button>
          </div>
        </div>
      </div>
      <div className="block block-shaded">
        <div className="title-large">
          Documentation
        </div>
        <div className="text">
          You can download the codebook for the National Courts component of the IUROPA CJEU Database as a <span className="code">.csv</span> file. The codebook includes a description of each variable, including the values that each value can take.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-national-courts-codebook.csv" download="IUROPA-CJEU-Database-national-courts-codebook.csv">Codebook</Button>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="title-large">
          Contributors
        </div>
        <Bio id="pavone" data={bios.pavone}/>
        <Bio id="wallerman-ghavanini" data={bios.wallermanGhavanini}/>
        <Bio id="brekke" data={bios.brekke}/>
      </div>
    </div>
  );
};
