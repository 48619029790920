// React components
import React from 'react'
import { Link, useNavigate } from "react-router-dom"

// MUI components
import Button from "@mui/material/Button";
import GitHubIcon from '@mui/icons-material/GitHub';
import SearchToolIcon from "@mui/icons-material/FindInPage";
import NetworkIcon from "@mui/icons-material/Hub";

// components
import ParallaxBlock from "./component-parallax-block";

// functions
import { makeCard } from "./utilities";
import { code } from "./utilities";
import { trackAction } from "./utilities";

export default function HomePage(props) {

  const navigate = useNavigate();

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        The IUROPA <br/> CJEU Database
      </div>
    </div>

  var databases = [
    {
      name: "cjeu_database_platform",
      label: "CJEU Database Platform",
      description: "CJEU Database Platform is at the core of the IUROPA CJEU Database. It contains data on the universe of CJEU cases, proceedings, decisions, judges and more. It covers the Court of Justice (CJ), General Court (GC), and Civil Service Tribunal (CST) for the Court's entire history (1952-2023).",
      link: "/cjeu-database-platform",
    },
    {
      name: "cjeu_text_corpus",
      label: "CJEU Text Corpus",
      description: "The CJEU Text Corpus includes the full text of all CJEU decisions (1952-2023), including judgments, orders, and Advocate General (AG) opinions, in both French and English (where an official translation exists). It also includes millions of machine-coded document-level and paragraph-level content tags.",
      link: "/cjeu-text-corpus",
    },
    {
      name: "issues_and_positions",
      label: "Issues and Positions",
      description: "The Issues and Positions component of the IUROPA CJEU Database includes data on the specific legal issues that the Court of Justice ruled on in each reference for a preliminary ruling (1995-2011). It indicates the position that the AG, member states, and EU institutions took on each legal issue based on the Reports for the Hearing.",
      link: "/issues-and-positions",
    },
    {
      name: "noncompliance",
      label: "Nonompliance Procedures ",
      description: "The Noncompliance Procedures component of the IUROPA CJEU Database includes data on decisions taken by the European Commission in the infringement procedure (2002-2023) and in the state aid procedure (1988-2023), including referrals to the CJEU.",
      link: "/noncompliance",
    },
    {
      name: "doctrine_and_legal_outcomes",
      label: "Doctrine and Legal Outcomes",
      description: "The Doctrine and Legal Outcomes component of the IUROPA CJEU Database includes information on the interaction between the legal outcome of cases and their political context for cases involving the free movement of goods, services, or persons.",
      link: "/doctrine-and-legal-outcomes",
    },
    {
      name: "national_courts",
      label: "National Courts",
      description: "The National Courts component of the IUROPA CJEU Database includes data on the national courts that can refer cases to the CJEU under the preliminary ruling procedure. It includes information about each referring court, including the level of the court in the judicial hierarchy, the prestige of the court, and the competence of the court.",
      link: "/national-courts",
    },
  ];

  return (
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-2.png" content={parallax} offset={"-20%"} />
      <div className="block">
        {/* <div className="title-large">
          IUROPA CJEU Database
        </div> */}
        <div className="text">
          The <b>IUROPA CJEU Database</b> is the most complete collection of data about the Court of Justice of the European Union (CJEU) and European Union (EU) law. The information in the database is based on public sources, cross-validated, corrected, cleaned, and systemitized into a research-ready format.
        </div>
        <div className="text">
          At the core of the IUROPA CJEU Database is the <b>IUROPA CJEU Database Platform</b>, which includes data on the universe of CJEU cases, proceedings, decisions, and judges. It also includes data on judicial appointments, parties in CJEU proceedings, legal procedures and outcomes, the assignment of judges to decisions, observers in references for a preliminary ruling, citations in decisions, and more. The IUROPA CJEU Database Platform version 2.00.00 is publicly available as of July 2024.
        </div>
        <div className="text">
          We are developing the <b>IUROPA CJEU Text Corpus</b>, a comprehensive collection of the texts of all types of judicial decisions of the CJEU.  Each document is split into paragraphs to enable more granular analyses. Where available, both the French and English texts are included. The IUROPA Text Corpus is a more complete database of CJEU texts than Curia or EUR-Lex with more than 10 million paragraphs.
        </div>
        <div className="text">
          The <b>Issues and Positions component</b> of the IUROPA CJEU Database contains information about the specific legal issues that the CJEU was asked to rule on in the preliminary ruling procedure during the period 1995-2011. It also contains information about the answers to these issues given by the Court in its judgement, the AG in its opinion, as well as the answers proposed by the parties to the case, the EU institutions and the Member States in their observations.
        </div>
        <div className="text">
          The <b>Noncompliance Procedures component</b> of the IUROPA CJEU Database contains case-level and decision-level data on the Commission's infringement procedure (2002-2023) and the Commission's state aid procedure (1998-2023). It also includes data on actions for failure to fulfill obligations at the CJEU (1952-2023).
        </div>
        <div className="text">
          The <b>Doctrine and Legal Outcomes component</b> of the IUROPA CJEU Database contains information on the interaction between the legal outcome of cases and their political context for cases involving the free movement of goods, services, or persons.
        </div>
        <div className="text">
          The <b>National Courts component</b> of the IUROPA CJEU Database contains information about every national court that has referred a references for a preliminary ruling that has proceeded to judgment (1952-2023). It includes information about the level of the court in the judicial hierarchy, the prestige of the court, and the competence of the court.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" component={Link} to="/about">
              About IUROPA
            </Button>
          </div>
        </div>
      </div>
      <div className="block block-dark">
        <div className="title-large white" variant="h4">
          IUROPA Explorer Tool
        </div>
        <div className="text white">
          You can use the IUROPA Explorer Tool to get an overview of the tables in IUROPA CJEU Database Platform, explore how they connect to each other together, and browse the that variables each table includes. We also provide a guide to help you easily and safely merge tables together to create a dataset for year research project.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              className="button-white"
              variant="contained"
              startIcon={<NetworkIcon />}
              component={Link} to="/explorer-tool"
              onClick={() => {
                trackAction("page_view", "/explorer-tool");
              }}
            >
              Explorer Tool
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="block">
        <div className="title-large" variant="h4">
          The IUROPA Report Tool
        </div>
        <div className="text">
          You can use the <span className="bold">IUROPA Report Tool</span> to generate reports on CJEU cases and judgments that summarize the data in the <span className="bold">IUROPA CJEU Database Platform</span>.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              className="button"
              variant="contained"
              startIcon={<SearchToolIcon/>}
              component={Link} to="/cjeu-database/cjeu-database-platform/report-tool"
              onClick={() => {
                trackAction("page_view", "/cjeu-database/cjeu-database-platform/iuropa-report-tool");
              }}
            >
              Report Tool
            </Button>
          </div>
        </div>
      </div> */}
      <div className="block block-shaded card-grid-container">
          <div className="card-grid">
            {databases.map((dataset, i) => (
              makeCard(dataset, i, 350, 525, () => navigate(dataset.link))
            ))}
            <div className="card-spacer" />
          </div>
      </div>
      {/* <div id="iuropa-r-package" className="block block-color">
        <div className="title-large white">
          IUROPA R Package
        </div>
      </div> */}
      <div id="iuropa-r-package" className="block">
      <div className="title-large">
          IUROPA R Package
        </div>
        <div className="text">
          The {code("iuropa")} package is an {code("R")} package for easily accessing data from components of the IUROPA CJEU Database, including the IUROPA CJEU Database Platform, via the IUROPA API. The <span className="code">iuropa</span> package is a wrapper around the API that makes it easy to query data from the database. The package includes tools for selecting observations and variables. It also provides access to the codebook, so you can look up variables without leaving <span className="code">R</span>.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              variant="contained"
              startIcon={<GitHubIcon />}
              component="a"
              target="_blank"
              href="https://www.github.com/jfjelstul/iuropa"
              onClick={() => {
                trackAction("external_link", "https://www.github.com/jfjelstul/iuropa")
              }}
            >
              GitHub
            </Button>
          </div>
        </div>
        <div className="title-small">
          Installation
        </div>
        <div className="text">
          You can install the latest development version of the <span className="code">iuropa</span> package from GitHub:
        </div>
        <pre className="code-block">
          {`# install.packages("devtools")
devtools::install_github("jfjelstul/iuropa")`}
        </pre>
        <div className="title-small">
          Citation
        </div>
        <div className="text">
          If you use the <span className="code">iuropa</span> package to access data from the IUROPA CJEU Database, please cite the package in addition to the database:
        </div>
        <div className="quote">
          Joshua C. Fjelstul (2024). iuropa: An R Interface to the IUROPA API. R package version 0.2.0.
        </div>
        <div className="text">
          The <span className="code">BibTex</span> entry for the package is:
        </div>
        <pre className="code-block">
          {`@Manual{,
  title = {iuropa: An R Interface to the IUROPA API},
  author = {Joshua C. Fjelstul},
  year = {2024},
  note = {R package version 0.2.0},
}`}
        </pre>
        <div className="title-small">
          Problems
        </div>
        <div className="text">
          If you notice an error in the data or a bug in the <span className="code">iuropa</span> package, please report it on <a className="link" target="_black" href="https://www.github.com/jfjelstul/iuropa/issues" onClick={() => {
            trackAction("external_link", "https://www.github.com/jfjelstul/iuropa/issues")
          }}>GitHub</a>.
        </div>
      </div>
    </div>
  );
};
