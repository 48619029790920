
const bios = {
  naurin: {
    name: "Daniel Naurin",
    institution: "The University of Oslo",
    preview: "Daniel Naurin is a professor in the Department of Political Science at the University of Oslo in Oslo, Norway. He is the director of ARENA.",
    bio: "Daniel Naurin is Professor of Political Science and Director of ARENA - Centre for European Studies at the University of Oslo. He is affiliated with the Department of Political Science at the University of Gothenburg. Naurin has worked with a broad range of research themes, including judicial politics, lobbying, transparency and international negotiations. Much of his empirical work has focused on the politics of the European Union.",
    photo: "/bio-photos/naurin.jpg",
    email: "mailto: daniel.naurin@arena.uio.no",
    website: "https://www.sv.uio.no/arena/english/people/aca/danienau/index.html",
    twitter: "https://twitter.com/DanielNaurin",
  },
  lindholm: {
    name: "Johan Lindholm",
    institution: "Umeå University",
    preview: "Johan Lindholm is a professor in the Department of Law at Umeå University in Umeå, Sweden.",
    bio: "Johan Lindholm is a Professor of Law at the Department of Law at Umeå University, with a focus on constitutional law, European Union law, and sports law. Much of his research focuses on the empirical examination of judicial institutions, primarily the CJEU, Swedish courts, and transnational arbitration in sports.",
    photo: "/bio-photos/lindholm.jpg",
    email: "mailto: johan.lindholm@umu.se",
    website: "https://www.umu.se/personal/johan-lindholm/",
    linkedin: "https://www.linkedin.com/in/johan-lindholm-umu/",
    twitter: "https://twitter.com/jlindholm",
  },
  sadl: {
    name: "Urška Šadl",
    institution: "The European University Institute",
    preview: "Urška Šadl is a professor in the Department of Law at The European University Institute in Florence, Italy.",
    bio: "Urska Šadl is a professor at the European University Institute in Florence, Italy, and a Global Research Fellow at iCourts, University of Copenhagen, Denmark. Most generally, she is committed to answering legal questions using a range of methods and techniques that gather under the  initiative NoLesLaw (the Network of Legal Empirical Scholars). The initiative was co-founded with Suvi Sankari and Johan Lindholm in 2015. Her research tries to understand the intricacies of the judicial process, which affect the content of legal rules, the protection of individual rights, and the division of competences between the European Union and its Member States. Currently, she is conducting a four year research project Judging under the influence. The project seeks to critically re-assess the role of legal actors and their impact on the Court of Justice of the European Union in the process of European integration through law. The project is financed by the SAPERE AUDE research leader grant (Danish Council for Independent Research).",
    photo: "/bio-photos/sadl.jpg",
    email: "mailto: urska.sadl@eui.eu",
    website: "https://www.eui.eu/people?id=urska-sadl",
  },
  wallermanGhavanini: {
    name: "Anna Wallerman Ghavanini",
    institution: "The University of Gothenburg",
    preview: "Anna Wallerman Ghavanini is an associate professor in the Department of Law at the University of Gothenburg in Gothenburg, Sweden.",
    bio: "Anna W. Ghavanini is associate professor of EU Law at The University of Gothenburg. She has previously held postdoctoral positions at the European University Institute and the University of Zurich. Her research interests include constitutional, European and procedural law as well as judicial discretion and judicial politics. In her recent work she has relied extensively on empirical and computer-assisted research methods for conducting legal research. Her ongoing projects concern the relationship between national courts and the EU Court of Justice, in particular in the field of equal treatment law, and the role of the judiciary within a system of separation of powers in the EU.",
    photo: "/bio-photos/wallerman-ghavanini.jpg",
    email: "mailto: anna.ghavanini@law.gu.se",
    website: "https://www.gu.se/om-universitetet/hitta-person/annawallerman",
    twitter: "https://twitter.com/AnnaWallerman",
  },
  brekke: {
    name: "Stein Arne Brekke",
    institution: "The European University Institute",
    preview: "Stein Arne Brekke is a Ph.D. researcher in the Department of Law at The European University Institute in Florence, Italy.",
    bio: "Stein Arne Brekke is a political scientist and Ph.D. researcher in law at the European University Institute in Florence, Italy. His doctoral research focuses on the role of the Court of Justice of the European Union as part of the political landscape of the European Union and its member states. He is interested in how the Court changes the nature of power dynamics between member states, how the Court is itself affected by the political context in which it operates, and the lessons that can be learned from the Court of Justice for efficient procedural design and the legitimization of international tribunals.",
    photo: "/bio-photos/brekke.jpg",
    email: "mailto: stein.brekke@eui.eu",
    website: "https://github.com/s-brekke",
  },
  fjelstul: {
    name: "Joshua Fjelstul",
    preview: "Joshua Fjelstul is a researcher at the ARENA Centre for European Studies at the University of Oslo in Oslo, Norway.",
    institution: "The University of Oslo",
    bio: "Joshua Fjelstul is a political economist and data scientist. He received his Ph.D. from Emory University in 2019. His research interests are at the intersection of political economy, comparative politics, and international relations. He studies the strategic interactions between domestic and international political institutions in legal disputes over policy implementation and compliance with international law. His empirical research focuses on the institutions of the European Union (EU). His methodological interests include analytical and computational game theory, structural estimators for game-theoretic equilibria, machine learning, and causal inference for policy evaluation. His research has appeared in the American Political Science Review (APSR), the Journal of European Public Policy (JEPP), the Journal of Law and Courts (JLC), the Journal of Theoretical Politics (JTP), European Union Politics (EUP), and International Interactions (II).",
    photo: "/bio-photos/fjelstul.jpg",
    email: "mailto: joshua.fjelstul@arena.uio.no",
    website: "http://www.joshuafjelstul.com",
    twitter: "https://twitter.com/joshfjelstul",
    github: "http://www.github.com/jfjelstul",
  },
  hermansen: {
    name: "Silje Synnøve Lyder Hermansen",
    institution: "The University of Copenhagen",
    preview: "Silje Synnøve Lyder Hermansen is a post-doctoral fellow at iCourts at the University of Copenhagen in Copenhagen, Denmark.",
    bio: "Silje Synnøve Lyder Hermansen currently works at iCourts, a center dedicated to the study of international courts at the University of Copenhagen. She holds a Ph.D. in political science from the University of Oslo and has previously worked at PluriCourts, a center of excellence funded by the Norwegian Science Foundation. Her research focuses on the selection and behavior of our representatives; be they legislators or judges. She is particularly interested in the Court of Justice of the European Union and the European Parliament. She relies mostly on quantitative methods, and has extensive experience in both automated data collection and Bayesian estimation strategies. Her research is featured in places like European Union Politics, Party Politics, and the Journal of European Public Policy.",
    photo: "/bio-photos/hermansen.jpg",
    email: "mailto: s.s.l.hermansen@jus.uio.no",
    website: "https://siljehermansen.github.io/ ",
    twitter: "https://twitter.com/lyderhermansen",
    github: "https://github.com/SiljeHermansen",
  },
  larsson: {
    name: "Olof Larsson",
    institution: "The University of Gothenburg",
    preview: "Olof Larsson is a researcher in the Department of Political Science at the University of Gothenburg in Gothenburg, Sweden.",
    bio: "Olof Larsson is a political scientist whose primary research interests are judicial politics; the interplay between economics, politics and law; compliance with international law; EU politics; and regional integration theory. He is affiliated with IUROPA; and the Centre for European Research (CERGU) and the Centre for Collective Action Research (CeCAR), both at the University of Gothenburg.",
    photo: "",
    email: "mailto: olof.g.larsson@gu.se",
    website: "https://www.gu.se/en/about/find-staff/olofglarsson",
  },
  ovadek: {
    name: "Michal Ovádek",
    institution: "The University of Gothenburg",
    preview: "Michal Ovádek is a post-doctoral researcher in the Department of Political Science at the University of Gothenburg in Gothenburg, Sweden.",
    bio: "Michal Ovádek is a postdoctoral researcher at the Department of Political Science, University of Gothenburg. His research focuses on the interplay between law and politics in various institutional contexts, primarily the European Union.",
    photo: "/bio-photos/ovadek.jpg",
    email: "mailto: michal.ovadek@gu.se",
    website: "https://michalovadek.github.io/",
    github: "https://github.com/michalovadek",
  },
  pavone: {
    name: "Tommaso Pavone",
    institution: "The University of Arizona",
    preview: "Tommaso Pavone is an assistant professor at the School of Government and Public Policy at the University of Arizona in Tucson, Arizona, United States.",
    bio: "Tommaso Pavone is an Assistant Professor of Law and Politics in the School of Government and Public Policy (SGPP) at the University of Arizona. He received his Ph.D. in 2019 from the Department of Politics at Princeton University. Pavone’s research and teaching interests span comparative politics, law and society, and judicial politics. Much of his work focuses on the European Union (EU) and traces how lawyers and courts impact processes of political development and social change. His research has been published in leading peer-reviewed journals, and his forthcoming book with Cambridge University Press - The Ghostwriters: Lawyers and the Politics Behind the Judicial Construction of Europe - has won several awards from the American Political Science Association (APSA), the Law and Society Association (LSA), and the European Union Studies Association (EUSA).",
    photo: "/bio-photos/pavone.jpg",
    email: "mailto: tpavone@arizona.edu",
    website: "https://www.tommasopavone.com/",
    twitter: "https://twitter.com/Tom_Pavone",
  },
  schroeder: {
    name: "Philipp Schroeder",
    institution: "Ludwig-Maximilians-University Munich",
    preview: "Philipp Schroeder is a post-doctoral researcher in the Department of Political Science at Ludwig-Maximilians-University Munich in Munich, Germany.",
    bio: "",
    photo: "/bio-photos/schroeder.jpg",
    email: "mailto: P.Schroeder@gsi.uni-muenchen.de",
    website: "https://phischroeder.github.io",
    twitter: "https://twitter.com/schroeder_pa",
  },
  stiansen: {
    name: "Øyvind Stiansen",
    institution: "The University of Oslo",
    preview: "Øyvind Stiansen is a post-doctoral fellow at PluriCourts at the University of Oslo in Oslo, Norway.",
    bio: "Øyvind Stiansen holds a Ph.D. in political science from the University of Oslo and is currently a postdoctoral research fellow at PluriCourts. Stiansen’s research employs data on the EFTA Court, the European Court of Human Rights, the Inter-American Court of Human Rights, and the international investment regime to study questions relating to judicial decision-making, judicial impact, and compliance with judicial decisions. His current research interests also include judicial politics in electoral autocracies and the response of the European Union to rule-of-law backsliding amongst its member states.",
    photo: "/bio-photos/stiansen.jpg",
    email: "mailto: oyvind.stiansen@jus.uio.no",
    website: "https://scholar.google.no/citations?user=57BRR8IAAAAJ&hl=no",
  },
  boulaziz: {
    name: "Louisa Boulaziz",
    // institution: "Research Assistant, PluriCourts Research Center, Faculty of Law, Data Scientist, Institute of Political Science, Social Science Faculty, University of Oslo",
    institution: "The European University Institute",
    preview: "Louisa Boulaziz is a Ph.D. researcher at The European University Institute in Florence, Italy.",
    bio: "Louisa Boulaziz defended her master degree in Political Science at the University of Oslo June 2021. In her thesis she wrote about how lawyers affect the decision-making of the Court of Justice of the European Union. During the course of her thesis Boulaziz worked as a research assistant at PluriCourts Research Center and taught seminars in applied statistics for students at the bachelor- and master-level in Political Science at the University of Oslo. Boulaziz will continue with her research as a Ph.D. researcher at the European University Institute starting fall 2021. Boulaziz is mainly focusing on empirical research and she will continue to explore research questions in the field of judicial politics and the politics of the European Union.",
    photo: "/bio-photos/boulaziz.jpg",
    email: "mailto: louisaboulaziz@gmail.com",
    linkedin: "http://www.linkedin.com/in/louisa-boulaziz-718154107",
  },
  cheruvu: {
    name: "Sivaram Cheruvu",
    institution: "The University of Texas at Dallas",
    preview: "Sivaram Cheruvu is an assistant professor of political science in the School of Economics, Politics, and Policy Sciences at The University of Texas at Dallas in Dallas, Texas, United States.",
    bio: "Sivaram Cheruvu is an Assistant Professor of Political Science within the School of Economics, Politics & Policy Sciences at the University of Texas at Dallas. He received his Ph.D. from Emory University in 2021. He researches comparative institutions with an emphasis on the conditions under which checks and balances can constrain the behavior of governments. In particular, his research spans judicial behavior, the interaction between domestic and international legal institutions, public support for legal institutions, and compliance with international law among other topics. He also frequently studies the institutions of the European Union and its member states to test the empirical implications of his theoretical arguments.",
    photo: "/bio-photos/cheruvu.jpg",
    email: "mailto: sivaram.cheruvu@utdallas.edu",
    website: "http://www.sivaramcheruvu.com",
    twitter: "http://www.twitter.com/sivcheruvu",
  },
  derlen: {
    name: "Mattias Derlén",
    institution: "Umeå University",
    preview: "Mattias Derlén is a professor in the Department of Law at Umeå University in Umeå, Sweden.",
    bio: "",
    photo: "",
    email: "mailto: mattias.derlen@umu.se",
    website: "https://www.umu.se/en/staff/mattias-derlen/",
  },
  espelid: {
    name: "Even Espelid",
    institution: "The University of Oslo",
    preview: "Even Espelid is a research assistant at PluriCourts at the University of Oslo in Oslo, Norway.",
    bio: "Even Espelid is a political scientist. He works as a research assistant at the PluriCourts research center at the University of Oslo, where he has mainly been working on developing the European Free Trade Association (EFTA) Court database. His most recent research project studied judicial politics at the EFTA Court.",
    photo: "/bio-photos/espelid.jpg",
    email: "mailto: even.espelid@jus.uio.no",
    website: "https://www.jus.uio.no/ior/english/people/vitass/evenes/index.html",
    linkedin: "https://www.linkedin.com/in/even-espelid-8b1659171/ ",
  },
  hofmann: {
    name: "Andreas Hofmann",
    institution: "Freie Universität Berlin",
    preview: "Andreas Hofmann is a researcher at the Otto Suhr Institute of Political Science at Freie Universität Berlin in Berlin, Germany.",
    bio: "Andreas Hofmann is a researcher at Freie Universität Berlin, where he is a member of the Center for European Integration at the Otto Suhr Institute of Political Science. His primary research interest is the role of courts in political processes and judicial procedures as a mode of solving political conflicts. In the past, this interest has mainly focussed on the European Court of Justice, the highest court of the European Union, but it now extends to courts and litigants in all European legal systems.",
    photo: "/bio-photos/hofmann.jpg",
    email: "mailto: andreas.hofmann@fu-berlin.de",
    website: "http://www.ahofmann.eu",
  },
  lopezZurita: {
    name: "Lucía López Zurita",
    institution: "The University of Copenhagen",
    preview: "Lucía López Zurita is a post-doctoral fellow at iCourts at the University of Copenhagen in Copenhagen, Denmark.",
    bio: "Lucía López Zurita is a Ph.D. candidate at the European University Institute (EUI) in Florence. Lucía holds an LLM on European Legal Practice from the Universities of Hannover and Lisbon, an LLM on European Union Law from the College of Europe (Belgium) and an LLM in European, Comparative and International Law from the EUI. Before joining the European University Institute, Lucía worked as an Academic Assistant in the Department of Legal Studies of the College of Europe. Her main areas of interest include judicial decision-making, especially at the Court of Justice of the European Union, EU procedural law, individual rights and free movement law.",
    photo: "/bio-photos/lopez.jpg",
    email: "mailto: lucia.lopez.zurita@jur.ku.dk",
    website: "https://research.ku.dk/search/result/?pure=en%2Fpersons%2F705626",
  },
  moberg: {
    name: "Andreas Moberg",
    institution: "The University of Gothenburg",
    preview: "Andreas Moberg is a senior lecturer in the Department of Law at the University of Gothenburg in Gothenburg, Sweden.",
    bio: "",
    photo: "",
    email: "mailto: andreas.moberg@law.gu.se",
    website: "https://www.gu.se/en/about/find-staff/andreasmoberg",
  },
  kim: {
    name: "Eun Hye Kim",
    institution: "The European University Institute",
    preview: "Eun Hye Kim is a Ph.D. researcher in the Department of Law at The European University Institute in Florence, Italy.",
    bio: "Eun Hye Kim is a postdoctoral researcher on the Judging Under the Influence Project. She defended her PhD thesis in October 2022 at the European University Institute (EUI) in Florence. Her thesis analyzed the role and influence of the Advocate General in the evolution of European competition law. Her principal areas of interest include judicial decision-making, judicial review of administrative decisions, empirical legal studies, and EU competition law. Eun Hye holds an LL.M. in European and Comparative Laws from the EUI, an LL.M. in Competition Law from King’s College London and a Master in EU Law from the Université Panthéon-Assas in Paris (Maîtrise). From 2012 to 2015 she studied at the European Law School at the Humboldt University of Berlin (State Examination). In 2020, Eun Hye was named as an International Scholar-in-Residence by the Antitrust Section of the American Bar Association and since October 2021, she is Assistant Special Editor (Competition Law) at the e-Journal \"European Forum\".",
    photo: "/bio-photos/kim.jpg",
    email: "mailto: Eun.Kim@eui.eu",
  },
  nilsson: {
    name: "Isak Nilsson",
    institution: "Umeå University",
    preview: "Isak Nilsson is a Ph.D. student in the Department of Law at Umeå University in Umeå, Sweden.",
    bio: "",
    photo: "",
    email: "mailto: isak.96@live.se",
    website: "https://www.umu.se/en/staff/isak-nilsson/",
  },
  nasstrom: {
    name: "Moa Näsström",
    institution: "The University of Gothenburg",
    preview: "Moa Näsström is an associate researcher in the Department of Political Science at Gothenburg University in Gothenburg, Sweden.",
    bio: "Moa Näsström is one of the hand coders for the IUROPA project. She received her Ph.D. from University of Leeds in 2016 in EU Law. Näsström’s main research interests are Food Law and Agricultural Law, which includes the roles of EU institutions. She is also interested in the balancing act of the Court when faced with cases where the Four Freedoms of EU Law are in conflict with Fundamental Rights.",
    photo: "/bio-photos/nasstrom.jpg",
    email: "mailto: moanasstrom15@gmail.com",
    website: "https://www.linkedin.com/in/drmoanasstrom/",
  },
  mandujano: {
    name: "Mauricio Mandujano Manriquez",
    institution: "The University of Oslo",
    preview: "Mauricio Mandujano Manriquez is a PhD Fellow at the ARENA Centre for European Studies of the University of Oslo in Oslo, Norway. ",
    bio: "Mauricio Mandujano's research focuses on the interaction between the Court of Justice of the European Union (CJEU) and the European Commission in relation to the violations of the rule of law and the core principles of EU membership. Additionally, his broader interests comprise the evolutionary trajectories of political systems, democratic institutions, and data science. Leveraging insights from political theory, he investigates how specific institutions such as courts, electoral systems, and legislatures evolve across time and space through scientific research designs. That is: how do sets of institutional arrangements vary in their performance across democratic polities? As for data science, he combines empirical research on judicial politics and novel data processing techniques.",
    photo: "/bio-photos/mandujano.jpg",
    email: "mailto: m.m.manriquez@arena.uio.no",
    website: " https://www.sv.uio.no/arena/english/people/aca/mauricmm/index.html",
  },
};

export { bios };
