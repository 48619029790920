import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/CloudDownload";

export default function CitationSection (props) {
  return(
    <div style={{ 
      display: "flex", 
      flexDirection: "column" 
    }}>
      <div className="text">
        <b>License.</b> The IUROPA CJEU Database Platform is published under a CC-BY-NC-SA 4.0 license (Attribution-NonCommercial-ShareAlike). You can use and share the data for non-commerical purposes as long as you provide proper attribution, provide a link to the license, and indicate if you made any changes to the database. To provide proper attribution under the CC-BY-NC-SA 4.0 license, you must provide the names of the authors, a copyright notice, a license notice, a disclaimer notice, and a link to the database. If you remix, transform, or build upon the database, you must distribute your contribution under the same license as the original.
      </div>
      <div className="text">
        <b>Citation.</b> If you use data from the IUROPA CJEU Database Platform, please cite the data using the following citation:
      </div>
      <div className="quote">
        Fjelstul, Joshua, Daniel Naurin, Stein Arne Brekke, and Silje Synnøve Lyder Hermansen. 2024. "The IUROPA CJEU Database Platform (v2.00.00)", in Lindholm, Johan, Daniel Naurin, Urska Sadl, Anna Wallerman Ghavanini, Stein Arne Brekke, Joshua Fjelstul, Silje Synnøve Lyder Hermansen, Olof Larsson, Andreas Moberg, Moa Näsström, Michal Ovádek, Tommaso Pavone, and Philipp Schroeder, <span className="italic">The Court of Justice of the European Union (CJEU) Database</span>, IUROPA, https://www.iuropa.pol.gu.se/.
      </div>
      <div className="row-centered">
        <div className="button-container-centered">
          <Button variant="text" href="/files/IUROPA-CJEU-Database-Platform-database-citation.bib" startIcon={<DownloadIcon/>}  download="IUROPA-CJEU-Database-Platform-database-citation.bib">
            BibTeX
          </Button>
        </div>
      </div>
      <div className="text">
        Please also cite the article that introduces the IUROPA CJEU Database Platform:
      </div>
      <div className="quote">
        Brekke, Stein Arne, Joshua C. Fjelstul, Silje Synnøve Lyder Hermansen, and Daniel Naurin. 2023. "The CJEU Database Platform: Decisions and Decision-Makers." Journal of Law and Courts 11(2): 389-410. <a className="https://doi.org/10.1017/jlc.2022.3">https://doi.org/10.1017/jlc.2022.3</a>.
      </div>
      <div className="row-centered">
        <div className="button-container-centered">
          <Button variant="text" href="/files/IUROPA-CJEU-Database-Platform-article-citation.bib" startIcon={<DownloadIcon/>} download="IUROPA-CJEU-Database-Platform-article-citation.bib">
            BibTeX
          </Button>
        </div>
      </div>
      <div className="text">
        If you use the IUROPA CJEU Database Platform in a paper, please <a className="link" href="mailto:joshua.fjelstul@arena.uio.no">let us know</a> so we can feature your paper on the IUROPA website.
      </div>
    </div>
  )
};
