// React components
import React, { useEffect, useState } from "react";
import { Route, Routes, Link, useLocation, useNavigate } from "react-router-dom";

// API components
import Axios from "axios";

// material-ui components
import Button from "@mui/material/Button";
import AccountCircle from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Cancel";

// page components
import Footer from "./component-footer";

// pages
import HomePage from "./page-home";
import AboutPage from "./page-about";
import DatabasePage from "./page-database";

// database component pages
import DatabasePlatformPage from "./page-database-platform";
import TextCorpusPage from "./page-database-text-corpus";
import NationalCourtsPage from "./page-database-national-courts";
import PartiesLawyersPage from "./page-database-parties-lawyers";
import IssuesPositionsPage from "./page-database-issues-positions";
import DoctrinePage from "./page-database-doctrine";
import NoncompliancePage from "./page-database-noncompliance";
import NerEditorPage from "./page-ner-editor";

// tools
import DownloadToolPage from "./page-tool-download";
import ReportToolPage from "./page-tool-report";
import ExplorerToolPage from "./page-tool-explorer";
import TextEditorToolPage from "./page-tool-text-editor";

// import DatabaseLandingPage from "./DatabaseLandingPage";
// import CodebookPage from "./CodebookPage";
// import APICodebookPage from "./APICodebookPage";
// import RPackagePage from "./RPackagePage";
// import CasesPage from "./CasesPage";
// import PartiesPage from "./PartiesPage";
// import DecisionsPage from "./DecisionsPage";
// import AssignmentsPage from "./AssignmentsPage";
// import ProceduresPage from "./ProceduresPage";
// import SubmissionsPage from "./SubmissionsPage";
// import CitationsPage from "./CitationsPage";
// import JudgesPage from "./JudgesPage";
// import AppointmentsPage from "./AppointmentsPage";
import AuthenticationPage from "./page-authentication";

// components
import Scroll from "./component-scroll";

// functions
import { trackAction } from "./utilities";

// import SmoothScroll from "smoothscroll-polyfill";
// SmoothScroll.polyfill();

function IsSelected(value) {
  const location = useLocation();
  return location.pathname.includes(value);
};

const Authenticate = (token) => {
  const [user, setUser] = React.useState(null);
  const url = "https://api.iuropa.pol.gu.se/check-authentication";
  const headers = {
    "Content-Type": "application/json",
    "authorization": token,
  }
  useEffect(() => {
    Axios.get(url, { headers: headers })
      .then(response => {
        setUser(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [token])
  return user;
};

// function SetTabIndicator () {
//   const location = useLocation();
//   if (location.pathname.includes("home")) {
//     return 0;
//   } else if (location.pathname.includes("components")) {
//     return 1;
//   } else if (location.pathname.includes("database")) {
//     return 2;
//   } else if (location.pathname.includes("about")) {
//     return 3;
//   } else {
//     return 0;
//   }
// }

// function HasBanner () {
//   const location = useLocation();
//   if (location.pathname === "/home" || location.pathname === "/components" || location.pathname === "/CJEU-database" || location.pathname === "/about") {
//     return true;
//   } else {
//     return false;
//   }
// }

// function HideOnScroll(props) {
//   const { children, window } = props;
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//   });
//
//   return (
//     <Slide appear={false} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

export default function App(props) {

  const [shadow, setShadow] = useState("0px 5px 30px 0px rgba(0, 0, 0, 0)");
  const [token, setToken] = useState(JSON.parse(sessionStorage.getItem("token")));
  const [logInScreenOn, setLogInScreenOn] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    trackAction("page_view", location.pathname);
  }, [location]);

  const updateAppBar = (newShadow) => {
    setShadow(newShadow);
  }

  const handleToken = (value) => {
    sessionStorage.setItem("token", JSON.stringify(value.token));
    setToken(value.token);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    setToken(null);
    navigate(0);
  }

  const hasBanner = () => {
    if (location.pathname === "/" || location.pathname === "/components" || location.pathname === "/CJEU-database" || location.pathname === "/about") {
      return true;
    } else {
      return false;
    }
  }

  const authenticated = Authenticate(token);

  var authenticationPanel = null;
  if (authenticated !== null) {
    authenticationPanel =
      <div className="authentication-container">
        <div className="username-container">
          <AccountCircle />
          <div className="username">{authenticated}</div>
        </div>
        <div className="authentication-button" onClick={handleLogout}>Log out</div>
      </div>
  } else {
    authenticationPanel =
      <div className="authentication-container">
        <div className="username-container">
          <AccountCircle />
          <Link className="authentication-button" to="/authentication">Log in</Link>
        </div>
      </div>
  }

  var appBar =
    <div className="app-bar">
      <div className="branding-container">
        <Link className="app-branding app-branding-large white" to={"/"}>
          IUROPA
        </Link>
      </div>
      <div className="tab-container">
        {/* <Link className="tab" to={"/cjeu-database"}>
          CJEU Database
        </Link> */}
        {/* <Link className="tab" to={"/cjeu-database"}>
          Research Tools
        </Link> */}
        {/* <Link className="tab" to={"/about"}>
          About
        </Link> */}
      </div>
      {authenticationPanel}
    </div>

  if (location.pathname.includes("tool")) {
    var appBarClass = "tool-app-bar"
    appBar =
      <div className={appBarClass}>
        <Link className="app-branding app-branding-large" to="/">
          IUROPA
        </Link>
        <div className="tool-back-button-container">
          <Button className="tool-back-button" startIcon={<CloseIcon/>} variant="text" component={Link} to="/">
            Close
          </Button>
        </div>
      </div>
  }

  var appFooter = <Footer />

  const restrictedPage =
    <div className="page-centered">
      <div class="text">
        Please <Link className="link" to="/authentication">log in</Link> to continue.
      </div>
    </div>

  const loginPage = <AuthenticationPage onSubmit={handleToken} />

  var content =
    <div>
      <Routes>
        <Route path="/" exact element={<DatabasePage />} />
        <Route path="/authentication" exact element={<AuthenticationPage />} />
        <Route path="/cjeu-database-platform" exact element={<DatabasePlatformPage />} />
        <Route path="/national-courts" exact element={<NationalCourtsPage />} />
        <Route path="/issues-and-positions" exact element={<IssuesPositionsPage />} />
        <Route path="/doctrine-and-legal-outcomes" exact element={<DoctrinePage />} />
        <Route path="/noncompliance" exact element={<NoncompliancePage />} />
        <Route path="/cjeu-text-corpus" exact element={<TextCorpusPage />} />
        <Route path="/cjeu-text-corpus/text-editor-tool" exact element={typeof (authenticated) === "string" ? <TextEditorToolPage username={authenticated} token={token} /> : loginPage} />
        <Route path="/explorer-tool" exact element={<ExplorerToolPage />} />
        <Route path="/download-tool" exact element={<DownloadToolPage />} />
        <Route path="/about" exact element={<AboutPage />} />
        <Route path="/NER-editor" exact element={<NerEditorPage />} />
      </Routes>
    </div>

  return (
    <div>
      {appBar}
      {content}
      {appFooter}
    </div>
  );
};
