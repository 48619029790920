// React components
import React, { Component } from 'react'
import { Link } from "react-router-dom";

// mui components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import DownloadIcon from '@mui/icons-material/CloudDownload';

// components
import ParallaxBlock from "./component-parallax-block";
import { datasets } from "./data-tables";
import { makeCard } from "./utilities";
import { code } from "./utilities";
import Bio from "./component-bio";
import { bios } from "./data-bios";

export default function HomePage(props) {

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        The IUROPA <br/> CJEU Database
      </div>
    </div>

  return (
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-7.png" content={parallax} offset={-250} />
      <div className="block block-shaded">
        <div className="title-large">
          Issues and Positions Component
        </div>
      </div>
      <div className="block">
        <div className="title-medium">
          By Olof Larsson, Johan Lindholm, Daniel Naurin, Andreas Moberg, Philipp Schroeder, Anna Wallerman Ghavanini, Sebastian Björnberg, Aaron Coster, Moa Näsström, and Irene Otero
        </div>
        <div className="text">
          The Issues and Positions component of the IUROPA CJEU Database contains information about the specific questions that the CJEU was asked to rule on in the preliminary ruling procedure. It covers the period from 1995 to 2011. It also contains information about the answers to these questions given by the Court in its judgement, the AG in its opinion, as well as the answers proposed by the parties to the case, the EU institutions and the Member States in their observations.
        </div>
        <div className="text">
          We refer to these questions as "legal issues" and the answers as "positions." There are three tables: one with legal issues as the unit of observation, one with positions as the unit of observation, and one with positions by actors as the unit of observation.
        </div>
        <div className="text">
          The information has been hand coded by a team of research associates (Sebastian Björnberg, Aaron Coster, Moa Näsström, Alexandra Nouvel and Irene Otero Fernández), supervised by researchers at the University of Gothenburg (Olof Larsson, Andreas Moberg, Daniel Naurin and Anna Wallerman Ghavanini) and Umeå University (Johan Lindholm and Philipp Schroeder).
        </div>
        <div className="title-small">
          Issues
        </div>
        <div className="text">
          The {code("issues")} table has one observation per legal issue per judgment. There are <span className="code">5333</span> legal issues across <span className="code">2233</span> judgments. This dataset includes a summary of each legal issue, whether two or more questions from the national court have been merged into one legal issue, whether the legal issue concerns derogations and justifications to violations of the principles of free movement, whether the legal issue concerns direct effect, whether the legal issue concerns the interpretation or validity of EU law, which categories of legal acts the legal issue concerns, which areas of national law the legal issue concerns, and which actors argued that the legal issue was inadmissible.
        </div>
        <div className="title-small">
          Positions
        </div>
        <div className="text">
          The {code("positions")} table has one observation per position per legal issue per judgment. There are <span className="code">18617</span> positions across the <span className="code">5333</span> legal issues. This dataset includes a summary of each position on each legal issue, which actors take each position, whether the position holds that an issue is for the national court to determine, how the position would affect the autonomy of the member state, and how the position would affect the scope of individual rights granted by EU law.
        </div>
        <div className="text">
          We also provide a {code("positions_by_actor")} table, which has one observation per actor per position per legal issue. There are <span className="code">41595</span> observations across <span className="code">18617</span> positions. This table is an expanded version of the {code("positions")} table. It uses the same IUROPA actor IDs as the IUROPA CJEU Database Platform.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-issues-and-positions-csv.zip" download="IUROPA-CJEU-Database-issues-and-positions-csv.zip">CSV</Button>
          </div>
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-issues-and-positions-RData.zip" download="IUROPA-CJEU-Database-issues-and-positions-RData.zip">RData</Button>
          </div>
        </div>
        <div className="title-small">
          Citation
        </div>
        <div className="text">
          If you use data from the Issues and Positions component of the IUROPA CJEU Database, please cite the data using the following citation:
        </div>
        <div className="quote">
          Larsson, Olof, Johan Lindholm, Daniel Naurin, Andreas Moberg, Philipp Schroeder, Anna Wallerman Ghavanini, Sebastian Björnberg, Aaron Coster, Moa Näsström and Irene Otero. 2022. "The IUROPA CJEU Database: Issues and Positions," in Lindholm, Johan, Daniel Naurin, Urška Šadl, Anna Wallerman Ghavanini, Stein Arne Brekke, Joshua Fjelstul, Silje Synnøve Lyder Hermansen, Olof Larsson, Andreas Moberg, Moa Näsström, Michal Ovádek, Tommaso Pavone, and Philipp Schroeder, <span className="italic">The IUROPA Court of Justice of the European Union (CJEU) Database</span>, IUROPA, https://www.iuropa.pol.gu.se/.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="text" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-issues-and-positions-citation.bib" download="IUROPA-CJEU-Database-issues-and-positions-citation.bib">
              BibTeX
            </Button>
          </div>
        </div>
      </div>
      <div className="block block-shaded">
        <div className="title-large">
          Documentation
        </div>
        <div className="text">
          You can download the codebook for theIssues and Positions component of the IUROPA CJEU Database as a <span className="code">.pdf</span> file. The codebook includes a description of each variable, including the values that each value can take, and a detailed explanation about how each variable was coded.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-issues-and-positions-codebook.pdf" download="IUROPA-CJEU-Database-issues-and-positions-codebook.pdf">Codebook</Button>
          </div>
        </div>
        <div className="text">
          You can also download a report as a <span className="code">.pdf</span> that discusses the results of reliability checks conducted for hand-coded variables. The report shows a high degree of inter-coder reliability.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-issues-and-positions-reliability-report.pdf" download="IUROPA-CJEU-Database-issues-and-positions-reliability-report.pdf">Report</Button>
          </div>
        </div>
      </div>
      <div className="block">
        <div className="title-large">
          Contributors
        </div>
        <Bio id="larsson" data={bios.larsson} />
        <Bio id="lindholm" data={bios.lindholm} />
        <Bio id="naurin" data={bios.naurin} />
        <Bio id="moberg" data={bios.moberg} />
        <Bio id="schroeder" data={bios.schroeder} />
        <Bio id="wallerman-ghavanini" data={bios.wallermanGhavanini} />
        <Bio id="nasstrom" data={bios.nasstrom} />
      </div>
    </div>
  );
};
