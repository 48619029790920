import React from 'react'
import { Parallax, Background } from "react-parallax"

export default function ParallaxBlock (props) {

  var { height, path, content, offset } = props;

  const [loaded, setLoaded] = React.useState(false);

  // if (text !== undefined) {
  //   var content =
  //   <div className="parallax-content">
  //     <div className="parallax-heading">
  //       {text}
  //     </div>
  //   </div>
  // }

  if (offset === undefined) {
    offset = 0
  }

  return(
    <div className="parallax-background">
      <Parallax
        blur={0}
        strength={400}
      >
        <Background>
          <img
            className={loaded ? "parallax-image" : "parallax-image-hidden"}
            src={path}
            style={{ marginTop: offset }}
            onLoad={() => setLoaded(true)}
          />
        </Background>
        <div style={{height: height}}>
        {content}
        </div>
        <div className="parallax-caption">
          © CJEU
        </div>
      </Parallax>
    </div>
  );
};
