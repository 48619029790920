import { parseText }  from "./utilities";

export default function CodebookComponent (props) {

  const { table } = props;

  let tables = require("./tables.json")
  let variables = require("./variables.json")

  var variablesSubset = variables.filter(item => {
    return item.table === table;
  });

  var tablesSubset = tables.filter(item => {
    return item.table === table;
  });

  const makeVariableDescription = (variable, i) => {
    const variableDescription =
    <div className="codebook-variable-container" key={i}>
      <div>
        <span className="codebook-variable-name">{variable.variable}</span>
        <span className="codebook-variable-type">{variable.data_type}</span>
      </div>
      <div className="codebook-variable-description">
        <div className="text">
          <div dangerouslySetInnerHTML={{__html: parseText(variable.description)}}/>
        </div>
      </div>
    </div>
    return variableDescription;
  }

  const makeDatasetDescription = (tablesSubset, variablesSubset) => {
    const datasetDescription =
    <div>
      <div className="text">
        <div dangerouslySetInnerHTML={{__html: parseText(tablesSubset[0].description)}}/>
      </div>
      <div className="codebook-variables-container">
        {variablesSubset.map((variable, i) => (
          makeVariableDescription(variable, i)
        ))}
      </div>
    </div>
    return datasetDescription;
  };

  return(
    <div className="codebook-container">
      {makeDatasetDescription(tablesSubset, variablesSubset)}
    </div>
  )
};
