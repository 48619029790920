// React components
import React, { Component, useEffect, useRef } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

// API components
import Axios from "axios";

// Material-UI components
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import SearchIcon from "@mui/icons-material/Search";
import LinearProgress from "@mui/material/LinearProgress";
import Drawer from "@mui/material/Drawer";
import UserIcon from "@mui/icons-material/AccountCircle";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import NextIcon from "@mui/icons-material/NavigateNext";
import BackIcon from "@mui/icons-material/NavigateBefore";
import AgreeIcon from "@mui/icons-material/CheckCircle";
import PreviewIcon from "@mui/icons-material/Visibility";
import CodebookIcon from "@mui/icons-material/Article";
import ResetIcon from "@mui/icons-material/RestartAlt";
import StartIcon from "@mui/icons-material/PlayCircleFilled";
import SelectIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";
import CancelIcon from "@mui/icons-material/Cancel";
import RestartIcon from "@mui/icons-material/RestartAlt";

// components
import DataTableComponent from "./component-data-table";
import CitationComponent from "./component-citation";
import CodebookComponent from "./component-codebook";
import { scroll } from "./utilities";
import { trackAction } from "./utilities";
import { code } from "./utilities";

export default function DownloadToolComponent(props) {

  var { username, token } = props

  let tables = require("./tables.json")
  let variables = require("./variables.json")

  tables = tables.filter((table) => {
    return table.is_supplemental === 0 && table.table !== "citations"
  })

  //--------------------------------------------------
  // state variables
  //--------------------------------------------------

  const [activeStep, setActiveStep] = React.useState(-1);
  const [dataset, setDataset] = React.useState("judgments");
  const [selectedVariables, setSelectedVariables] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = React.useState([]);

  const [codebookDialogOpen, setCodebookDialogOpen] = React.useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = React.useState(false);

  const [data, setData] = React.useState(null);
  const [observations, setObservations] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const headers = {
    "Content-Type": "application/json",
    "authorization": token,
  }

  const updateSelectedFilters = (variable, value) => {

    // create a new array
    var listFilters = [...selectedFilters];
    // console.log("old filters");
    // console.log(listFilters);

    // create a new filter from the function input
    const newFilter = {
      variable: variable,
      value: value
    };

    // find the filter in the array if one already exists for this variable
    var selectedFilter = listFilters.filter(item => item.variable === newFilter.variable);

    var updatedFilters;

    // if a filter already exists...
    if (selectedFilter.length > 0) {

      // get the index of the filter
      const index = listFilters.map(item => item.variable).indexOf(variable)

      // if the new value is empty...
      if (value.length === 0) {

        // remove the filter
        listFilters.splice(index, 1);

        // save updated filters
        updatedFilters = listFilters;

        // if the new value isn't empty...
      } else {

        // replace the filter with the new one
        listFilters[index] = newFilter;

        // save updated filters
        updatedFilters = listFilters;
      }

      // if a filter doesn't already exist...
    } else {

      // add the filter to the array
      updatedFilters = [...listFilters, newFilter];
    }

    setSelectedFilters(updatedFilters);
    // console.log("new filters");
    // console.log(updatedFilters);
  }

  const getFilterValue = (variable) => {

    let value = "";

    if (selectedFilters) {
      if (selectedFilters.length > 0) {

        const selectedFilter = selectedFilters.filter(item => item.variable === variable);

        if (selectedFilter.length > 0) {
          value = selectedFilter[0].value;
        }
      }
    }

    return value;
  }

  const updateSelectedVariables = (variable) => {
    var list = [...selectedVariables];
    if (list.includes(variable)) {
      const index = list.indexOf(variable)
      if (index > -1) {
        list.splice(index, 1);
        setSelectedVariables(list);
      }
    } else {
      setSelectedVariables([...list, variable]);
    }
  }

  const convertStringToArray = (value) => {
    if (value === "") {
      return [];
    } else {
      return value;
    }
  }

  //--------------------------------------------------
  // API methods
  //--------------------------------------------------

  const getPreviewData = () => {
    setIsLoaded(false);
    const query = buildQuery() + "&limit=100";
    Axios.get(query, { headers: headers })
      .then(response => {
        console.log(response.data.data)
        setData(response.data.data);
        setIsLoaded(true);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getObservations = () => {
    const query = buildQuery() + "&count=1";
    Axios.get(query, { headers: headers })
      .then(response => {
        console.log(response.data.data)
        setObservations(response.data.data[0].observations)
        console.log(response.data.data[0].observations)
      })
      .catch(error => {
        console.log(error);
      });
  };

  const downloadData = () => {
    setIsDownloading(true);
    const query = buildQuery() + "&download=zip";
    trackAction("download_tool_download", query);
    Axios.get(query, { headers: headers })
      .then(response => {
        let url = null;
        let file = null;
        if (query.includes("&download=zip")) {
          url = "data:application/zip;base64," + response.data.content;
          file = "data.zip";
        }
        if (query.includes("&download=csv")) {
          url = window.URL.createObjectURL(new Blob([response.data]));
          file = "data.csv";
        }
        var a = document.createElement("a");
        a.href = url;
        a.setAttribute("download", file);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsDownloading(false);
      });
  };

  const buildQuery = () => {
    var query = "";
    var conditions = "";
    for (let i = 0; i < selectedFilters.length; i++) {
      var filterText = "&" + selectedFilters[i].variable + "=" + selectedFilters[i].value;
      conditions += filterText;
    }
    var variables = "&variables=" + selectedVariables.join(",");
    const API = "https://api.iuropa.pol.gu.se";
    // const API = "http://localhost:4000";
    query = API + "/database/data/cjeu_database_platform/" + dataset + "?" + conditions + variables;
    console.log(query)
    return query;
  };

  //--------------------------------------------------
  // interface methods
  //--------------------------------------------------

  const handleRestartButton = () => {
    setActiveStep(0);
  }

  const handleNextButton = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackButton = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDownloadButton = () => {
    downloadData();
  };

  const resetFilters = () => {
    setSelectedFilters([]);
    setSelectedFilterValues([]);
    setSelectedVariables(getVariables());
  }

  const getVariables = () => {

    const listVariables = variables
      .filter((variable) => {
        return variable.table === dataset;
      })
      .map(item => item.variable);

    return listVariables;
  }

  const deselectAllVariables = () => {
    setSelectedVariables([]);
  }

  const selectAllVariables = () => {
    setSelectedVariables(getVariables());
  }

  const makeHeader = () => {
    var header = [];
    var variables = getVariables();
    for (let i = 0; i < variables.length; i++) {
      if (selectedVariables.includes(variables[i])) {
        header.push(variables[i]);
      }
    }
    return header;
  }

  //--------------------------------------------------
  // codebook dialog
  //--------------------------------------------------

  const codebookDialog =
    <Dialog
      open={codebookDialogOpen}
      onClose={() => setCodebookDialogOpen(false)}
      PaperProps={{ className: "download-tool-codebook-dialog-paper" }}
      BackdropProps={{ className: "dialog-backdrop" }}
    >
      <DialogTitle>
        Codebook
      </DialogTitle>
      <DialogContent>
        <CodebookComponent table={dataset} />
      </DialogContent>
    </Dialog>

  //--------------------------------------------------
  // preview dialog
  //--------------------------------------------------

  const previewDialog =
    <Dialog open={previewDialogOpen}
      onClose={() => setPreviewDialogOpen(false)}
      PaperProps={{ className: "download-tool-preview-dialog-paper" }}
      BackdropProps={{ className: "dialog-backdrop" }}
    >
      <DialogTitle>
        Preview Your Table
      </DialogTitle>
      <DialogContent>
        <div className="tool-message-container" style={{ paddingTop: 0, paddingBottom: 0, }}>
          <div className="tool-message-box">
            <div className="tool-message-icon-container">
              <InfoIcon className="tool-message-icon" />
            </div>
            <div className="tool-message-text">
              Your query returned {observations} observations. This preview shows the first 100 of those observations.
            </div>
          </div>
        </div>
        <div className="download-tool-preview-container">
          <DataTableComponent bodyData={data} headerData={makeHeader()} width={"100%"} />
        </div>
      </DialogContent>
    </Dialog>

  //--------------------------------------------------
  // select observations form
  //--------------------------------------------------

  const courtField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Court</InputLabel>
        <Select
          style={{ width: 275 }}
          variant="filled"
          value={convertStringToArray(getFilterValue("court"))}
          multiple
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("court", event.target.value)}
        >
          <MenuItem value="Court of Justice">Court of Justice</MenuItem>
          <MenuItem value="General Court">General Court</MenuItem>
          <MenuItem value="Civil Service Tribunal">Civil Service Tribunal</MenuItem>
        </Select>
      </FormControl>
    </div>

  const caseYearMinField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="Case year (min)"
        value={getFilterValue("case_year_min")}
        onChange={(event) => updateSelectedFilters("case_year_min", event.target.value)}
      />
    </div>

  const caseYearMaxField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="Case year (max)"
        value={getFilterValue("case_year_max")}
        onChange={(event) => updateSelectedFilters("case_year_max", event.target.value)}
      />
    </div>

  const isPendingField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Pending</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("is_pending")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("is_pending", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Is pending</MenuItem>
          <MenuItem value="0">Is not pending</MenuItem>
        </Select>
      </FormControl>
    </div>

  const isRemovedField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Removed</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("is_removed")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("is_removed", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Is removed</MenuItem>
          <MenuItem value="0">Is not removed</MenuItem>
        </Select>
      </FormControl>
    </div>

  const isTransferredField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Transferred</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("is_transferred")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("is_transferred", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Is transferred</MenuItem>
          <MenuItem value="0">Is not transferred</MenuItem>
        </Select>
      </FormControl>
    </div>

  const isJoinedField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Joined</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("is_joined")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("is_joined", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Is joined</MenuItem>
          <MenuItem value="0">Is not joined</MenuItem>
        </Select>
      </FormControl>
    </div>

  const hasJudgmentField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Judgment</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("has_judgment")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("has_judgment", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Has judgment</MenuItem>
          <MenuItem value="0">No judgment</MenuItem>
        </Select>
      </FormControl>
    </div>

  const hasOpinionField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Opinion</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("has_opinion")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("has_opinion", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Has opinion</MenuItem>
          <MenuItem value="0">No opinion</MenuItem>
        </Select>
      </FormControl>
    </div>

  const decisionDateMinField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="Decision date (min)"
        value={getFilterValue("decision_date_min")}
        helperText="Format: YYYY-MM-DD"
        onChange={(event) => updateSelectedFilters("decision_date_min", event.target.value)}
      />
    </div>

  const decisionDateMaxField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="Decision date (max)"
        value={getFilterValue("decision_date_max")}
        helperText="Format: YYYY-MM-DD"
        onChange={(event) => updateSelectedFilters("decision_date_max", event.target.value)}
      />
    </div>

  const decisionTypeField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Decision type</InputLabel>
        <Select
          style={{ width: 275 }}
          variant="filled"
          multiple
          value={convertStringToArray(getFilterValue("decision_type"))}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("decision_type", event.target.value)}
        >
          <MenuItem value="AG opinion">AG opinion</MenuItem>
          <MenuItem value="AG view">AG view</MenuItem>
          <MenuItem value="decision">Decision</MenuItem>
          <MenuItem value="judgment">Judgment</MenuItem>
          <MenuItem value="opinion">Opinion</MenuItem>
          <MenuItem value="order">Order</MenuItem>
          <MenuItem value="ruling">Ruling</MenuItem>
          <MenuItem value="seizure order">Seizure order</MenuItem>
          <MenuItem value="third-party proceedings">Third-party proceedings</MenuItem>
        </Select>
      </FormControl>
    </div>

  const memberStateField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Member state</InputLabel>
        <Select
          style={{ width: 275 }}
          variant="filled"
          multiple
          value={convertStringToArray(getFilterValue("member_state"))}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("member_state", event.target.value)}
        >
          <MenuItem value="Austria">Austria</MenuItem>
          <MenuItem value="Belgium">Belgium</MenuItem>
          <MenuItem value="Bulgaria">Bulgaria</MenuItem>
          <MenuItem value="Croatia">Croatia</MenuItem>
          <MenuItem value="Cyprus">Cyprus</MenuItem>
          <MenuItem value="Czechia">Czechia</MenuItem>
          <MenuItem value="Denmark">Denmark</MenuItem>
          <MenuItem value="Estonia">Estonia</MenuItem>
          <MenuItem value="Finland">Finland</MenuItem>
          <MenuItem value="France">France</MenuItem>
          <MenuItem value="Germany">Germany</MenuItem>
          <MenuItem value="Greece">Greece</MenuItem>
          <MenuItem value="Hungary">Hungary</MenuItem>
          <MenuItem value="Ireland">Ireland</MenuItem>
          <MenuItem value="Italy">Italy</MenuItem>
          <MenuItem value="Latvia">Latvia</MenuItem>
          <MenuItem value="Lithuania">Lithuania</MenuItem>
          <MenuItem value="Luxembourg">Luxembourg</MenuItem>
          <MenuItem value="Malta">Malta</MenuItem>
          <MenuItem value="Netherlands">Netherlands</MenuItem>
          <MenuItem value="Poland">Poland</MenuItem>
          <MenuItem value="Portugal">Portugal</MenuItem>
          <MenuItem value="Romania">Romania</MenuItem>
          <MenuItem value="Slovakia">Slovakia</MenuItem>
          <MenuItem value="Slovenia">Slovenia</MenuItem>
          <MenuItem value="Spain">Spain</MenuItem>
          <MenuItem value="Sweden">Sweden</MenuItem>
          <MenuItem value="United Kingdom">United Kingdom</MenuItem>
        </Select>
      </FormControl>
    </div>

  const isFemaleField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Gender</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("is_female")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("is_female", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Female</MenuItem>
          <MenuItem value="0">Male</MenuItem>
        </Select>
      </FormControl>
    </div>

  const isCurrentField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Status</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          value={getFilterValue("is_current")}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("is_current", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="1">Current</MenuItem>
          <MenuItem value="0">Former</MenuItem>
        </Select>
      </FormControl>
    </div>

  const startDateMinField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="Start date (min)"
        value={getFilterValue("start_date_min")}
        helperText="Format: YYYY-MM-DD"
        onChange={(event) => updateSelectedFilters("start_date_min", event.target.value)}
      />
    </div>

  const startDateMaxField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="Start date (max)"
        value={getFilterValue("start_date_max")}
        helperText="Format: YYYY-MM-DD"
        onChange={(event) => updateSelectedFilters("start_date_max", event.target.value)}
      />
    </div>

  const endDateMinField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="End date (min)"
        value={getFilterValue("end_date_min")}
        helperText="Format: YYYY-MM-DD"
        onChange={(event) => updateSelectedFilters("end_date_min", event.target.value)}
      />
    </div>

  const endDateMaxField =
    <div className="form-container">
      <TextField
        style={{ width: 225 }}
        variant="filled"
        label="End date (max)"
        value={getFilterValue("end_date_max")}
        helperText="Format: YYYY-MM-DD"
        onChange={(event) => updateSelectedFilters("end_date_max", event.target.value)}
      />
    </div>

  const positionField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Position</InputLabel>
        <Select
          style={{ width: 225 }}
          variant="filled"
          multiple
          value={convertStringToArray(getFilterValue("position"))}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("position", event.target.value)}
        >
          <MenuItem className="italic" value={""}>Any</MenuItem>
          <MenuItem value="judge">judge</MenuItem>
          <MenuItem value="Advocate General">Advocate General</MenuItem>
          <MenuItem value="Vice-President">Vice-President</MenuItem>
          <MenuItem value="President">President</MenuItem>
        </Select>
      </FormControl>
    </div>

  const partyRoleField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Party role</InputLabel>
        <Select
          style={{ width: 275 }}
          variant="filled"
          multiple
          value={convertStringToArray(getFilterValue("party_role"))}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("party_role", event.target.value)}
        >
          <MenuItem value="appellant">Appellant</MenuItem>
          <MenuItem value="applicant">Applicant</MenuItem>
          <MenuItem value="applicant at first instance">Applicant at first instance</MenuItem>
          <MenuItem value="defendant">Defendant</MenuItem>
          <MenuItem value="defendant at first instance">Defendant at first instance</MenuItem>
          <MenuItem value="intervener">Intervener</MenuItem>
          <MenuItem value="intervener at first instance">Intervener at first instance</MenuItem>
          <MenuItem value="intervener in the appeal">Intervener in the appeal</MenuItem>
          <MenuItem value="litigant">Litigant</MenuItem>
        </Select>
      </FormControl>
    </div>

  // const partyTypeField =
  //   <div className="form-container">
  //     <FormControl>
  //       <InputLabel>Party type</InputLabel>
  //       <Select
  //         style={{ width: 275 }}
  //         variant="filled"
  //         multiple
  //         value={partyTypeFilter}
  //         IconComponent={SelectArrow}
  //         onChange={(event) => setPartyTypeFilter(event.target.value)}
  //       >
  //         <MenuItem value="EU (general)">EU (general)</MenuItem>
  //         <MenuItem value="EU member state">EU member state</MenuItem>
  //         <MenuItem value="EU institution">EU institution</MenuItem>
  //         <MenuItem value="EU body">EU body</MenuItem>
  //         <MenuItem value="EU exeuctive agency">EU executive agency</MenuItem>
  //         <MenuItem value="EU decentralized agency">EU decentralized agency</MenuItem>
  //         <MenuItem value="EFTA member state">EFTA member state</MenuItem>
  //         <MenuItem value="legal person">Legal person</MenuItem>
  //       </Select>
  //     </FormControl>
  //   </div>

  const procedureField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Procedure</InputLabel>
        <Select
          style={{ width: 275 }}
          variant="filled"
          multiple
          value={convertStringToArray(getFilterValue("procedure"))}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("procedure", event.target.value)}
        >
          <MenuItem value="action for annulment">Action for annlument</MenuItem>
          <MenuItem value="action for damages">Action for damages</MenuItem>
          <MenuItem value="action for failure to act">Action for failure to act</MenuItem>
          <MenuItem value="action for failure to fufill obligations">Action for failure to fufill obligations</MenuItem>
          <MenuItem value="appeal">Appeal</MenuItem>
          <MenuItem value="appeal against a penalty">Appeal against a penalty</MenuItem>
          <MenuItem value="application as to costs">Application as to costs</MenuItem>
          <MenuItem value="application for interim measures">Application for interim measures</MenuItem>
          <MenuItem value="application for interpreation">Application for interpretation</MenuItem>
          <MenuItem value="application for measures of inquiry">Application for measures of inquiry</MenuItem>
          <MenuItem value="application for revision">Application for revision</MenuItem>
          <MenuItem value="application to intervene">Application to intervene</MenuItem>
          <MenuItem value="application to set aside by default">Application to set aside by default</MenuItem>
          <MenuItem value="arbitration clause">Arbitration clause</MenuItem>
          <MenuItem value="reference for a preliminary ruling">Reference for a preliminary ruing</MenuItem>
          <MenuItem value="resposibility of a member of the institutions">Responsibility of a member of the institutions</MenuItem>
          <MenuItem value="review">Review</MenuItem>
          <MenuItem value="staff case">Staff case</MenuItem>
        </Select>
      </FormControl>
    </div>

  const actorTypeField =
    <div className="form-container">
      <FormControl>
        <InputLabel>Actor type</InputLabel>
        <Select
          style={{ width: 275 }}
          variant="filled"
          multiple
          value={convertStringToArray(getFilterValue("actor_type"))}
          IconComponent={SelectArrow}
          onChange={(event) => updateSelectedFilters("actor_type", event.target.value)}
        >
          <MenuItem value="EAEC">EAEC</MenuItem>
          <MenuItem value="EAEC agency">EAEC agency</MenuItem>
          <MenuItem value="EAEC institution">EAEC institution</MenuItem>
          <MenuItem value="EAEC joint undertaking">EAEC joint undertaking</MenuItem>
          <MenuItem value="ECSC">ECSC</MenuItem>
          <MenuItem value="ECSC institution">ECSC institution</MenuItem>
          <MenuItem value="EEA">EEA</MenuItem>
          <MenuItem value="EFTA">EFTA</MenuItem>
          <MenuItem value="EFTA institution">EFTA institution</MenuItem>
          <MenuItem value="EFTA member state">EFTA member state</MenuItem>
          <MenuItem value="EU">EU</MenuItem>
          <MenuItem value="EU body">EU body</MenuItem>
          <MenuItem value="EU corporate body">EU corporate body</MenuItem>
          <MenuItem value="EU decentralized agency">EU decentralized agency</MenuItem>
          <MenuItem value="EU institution">EU institution</MenuItem>
          <MenuItem value="EU joint undertaking">EU joint undertaking</MenuItem>
          <MenuItem value="EU miscellaneous actor">EU miscellaneous</MenuItem>
          <MenuItem value="EU member state">EU member state</MenuItem>
        </Select>
      </FormControl>
    </div>

  // const observerTypeField =
  //   <div className="form-container">
  //     <FormControl>
  //       <InputLabel>Observer type</InputLabel>
  //       <Select
  //         style={{ width: 275 }}
  //         variant="filled"
  //         multiple
  //         value={observerTypeFilter}
  //         IconComponent={SelectArrow}
  //         onChange={(event) => setObserverTypeFilter(event.target.value)}
  //       >
  //         <MenuItem value="EU member state">EU member state</MenuItem>
  //         <MenuItem value="EU institution">EU institution</MenuItem>
  //         <MenuItem value="EU body">EU body</MenuItem>
  //         <MenuItem value="EU decentralized agency">EU decentralized agency</MenuItem>
  //         <MenuItem value="EFTA member state">EFTA member state</MenuItem>
  //         <MenuItem value="EFTA institution">EFTA institution</MenuItem>
  //       </Select>
  //     </FormControl>
  //   </div>

  const decisionFilters =
    <div>
      {courtField}
      <div className="form-row">
        {caseYearMinField}
        {caseYearMaxField}
      </div>
      <div className="form-row">
        {decisionTypeField}
      </div>
      <div className="form-row">
        {decisionDateMinField}
        {decisionDateMaxField}
      </div>
    </div>

  const decisionSubTableFilters =
    <div>
      {courtField}
      <div className="form-row">
        {caseYearMinField}
        {caseYearMaxField}
      </div>
      <div className="form-row">
        {decisionDateMinField}
        {decisionDateMaxField}
      </div>
    </div>

  const caseFilters =
    <div>
      {courtField}
      <div className="form-row">
        {caseYearMinField}
        {caseYearMaxField}
      </div>
      <div className="form-row">
        {isRemovedField}
      </div>
      <div className="form-row">
        {isTransferredField}
        {isJoinedField}
      </div>
      <div className="form-row">
        {hasJudgmentField}
        {hasOpinionField}
      </div>
    </div>

  const judgeFilters =
    <div>
      {memberStateField}
      <div className="form-row">
        {isFemaleField}
        {isCurrentField}
      </div>
      <div className="form-row">
        {startDateMinField}
        {startDateMaxField}
      </div>
      <div className="form-row">
        {endDateMinField}
        {endDateMaxField}
      </div>
    </div>

  var filtersForm = null;

  if (dataset === "cases") {
    filtersForm =
      <div>
        {caseFilters}
      </div>
  }

  if (dataset === "proceedings") {
    filtersForm =
      <div>
        {caseFilters}
      </div>
  }

  if (dataset === "appeals" || dataset === "direct_actions" || dataset === "preliminary_rulings") {
    filtersForm =
      <div>
        {courtField}
        <div className="form-row">
          {caseYearMinField}
          {caseYearMaxField}
        </div>
      </div>
  }

  if (dataset === "decisions" || dataset === "judgments" || dataset === "opinions") {
    filtersForm =
      <div>
        {decisionFilters}
      </div>
  }

  if (dataset == "judges") {
    filtersForm =
      <div>
        {judgeFilters}
      </div>
  }

  if (dataset == "appointments") {
    filtersForm =
      <div>
        {judgeFilters}
        {courtField}
        {positionField}
      </div>
  }

  if (dataset == "actors") {
    filtersForm =
      <div>
        {actorTypeField}
      </div>
  }

  if (dataset == "formations") {
    filtersForm =
      <div>
        {courtField}
      </div>
  }

  if (dataset == "panels") {
    filtersForm =
      <div>
        {courtField}
        <div className="form-row">
          {startDateMinField}
          {startDateMaxField}
        </div>
        <div className="form-row">
          {endDateMinField}
          {endDateMaxField}
        </div>
      </div>
  }

  if (dataset === "parties_by_proceeding") {
    filtersForm =
      <div>
        {courtField}
        <div className="form-row">
          {caseYearMinField}
          {caseYearMaxField}
        </div>
      </div>
  }

  if (dataset === "parties_by_judgment") {
    filtersForm =
      <div>
        {courtField}
        <div className="form-row">
          {caseYearMinField}
          {caseYearMaxField}
        </div>
        <div className="form-row">
          {partyRoleField}
        </div>
      </div>
  }

  if (dataset == "observers" || dataset == "lawyers") {
    filtersForm =
      <div>
        <div className="form-row">
          {caseYearMinField}
          {caseYearMaxField}
        </div>
        <div className="form-row">
          {decisionDateMinField}
          {decisionDateMaxField}
        </div>
      </div>
  }

  if (dataset === "procedures") {
    filtersForm =
      <div>
        {decisionSubTableFilters}
        {procedureField}
      </div>
  }

  if (dataset === "assignments" || dataset === "procedural_events" || dataset === "decision_effects" || dataset === "directory_codes") {
    filtersForm =
      <div>
        {decisionSubTableFilters}
      </div>
  }

  if (dataset === "referrals") {
    filtersForm =
      <div>
        <div className="form-row">
          {caseYearMinField}
          {caseYearMaxField}
        </div>
        <div className="form-row">
          {decisionDateMinField}
          {decisionDateMaxField}
        </div>
        {memberStateField}
      </div>
  }


  //--------------------------------------------------
  // select variables form
  //--------------------------------------------------

  var variablesForm =
    <div>
      <div className="checkbox-grid-container">
        {getVariables().map((item, i) => (
          <div className="checkbox-container">
            <FormControlLabel
              control={
                <Checkbox checked={selectedVariables.includes(item)} onChange={() => updateSelectedVariables(item)} />
              }
              label={item}
            />
          </div>
        ))}
      </div>
    </div>

  //--------------------------------------------------
  // stepper
  //--------------------------------------------------

  // automatically scroll stepper to the top of the step
  // useEffect(() => {
  //   if(activeStep > -1) {
  //     scroll("stepper", (activeStep * 72) - 72 - 150);
  //   }
  // }, [activeStep])

  var downloadBar =
    <div className={isDownloading ? "download-tool-progress-bar-container" : "download-tool-progress-bar-container-hidden"}>
      <div className="download-tool-progress-bar">
        <LinearProgress />
      </div>
      <div className="download-tool-progress-bar-caption">
        Downloading. This may take a moment...
      </div>
    </div>

  const stepper =
    <div id="stepper" className="download-tool-stepper-container">
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step>
          <StepLabel id="step-1">
            Select a table
          </StepLabel>
          <StepContent>
            <div className="text">
              First, select a table. The IUROPA CJEU Database Platform currently includes <span className="code">24</span> tables, and each table has a different unit of observation. Data from the <span className="code">citations</span> table is not currently available through this tool because of the large number of observations. If you need citation data, please bulk download the entire database.
            </div>
            <Box className="form-container">
              <FormControl>
                <InputLabel>Unit of observation</InputLabel>
                <Select
                  style={{ width: 275 }}
                  variant="filled"
                  value={dataset}
                  onChange={(event) => setDataset(event.target.value)}
                  IconComponent={SelectArrow}
                >
                  {tables.map((item, i) => (
                    <MenuItem key={i} value={item.table}>{item.table_label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <div className="form-button-row">
              <div className="form-container">
                <Button
                  variant="contained"
                  startIcon={<NextIcon />}
                  onClick={() => {
                    handleNextButton();
                    resetFilters();
                  }}
                >
                  Next
                </Button>
              </div>
              <div className="form-container">
                <Button startIcon={<BackIcon />} onClick={handleBackButton}>
                  Back
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step>
          <StepLabel id="step-2">
            Select observations
          </StepLabel>
          <StepContent>
            <div className="text">
              Second, use filters below to select the observations you'd like to include in your table. By default, all observations are included. Use the button below to consult the codebook if you have a question about one of the filter variables. There are many ways to filter the data, but the filters below cover the most common use cases. If you want to apply a more advanced set of filters, you can download the entire database and apply your own.
            </div>
            {filtersForm}
            <div className="form-button-row">
              <div className="form-container">
                <Button
                  variant="contained"
                  startIcon={<NextIcon />}
                  onClick={() => {
                    handleNextButton();
                  }}
                >
                  Next
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<BackIcon />} onClick={handleBackButton}>
                  Back
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<ResetIcon />} onClick={resetFilters}>
                  Clear
                </Button>
              </div>
              <div className="form-container">
                <Button
                  variant="text"
                  startIcon={<CodebookIcon />}
                  onClick={() => {
                    setCodebookDialogOpen(true);
                    trackAction("download_tool_open_codebook", "none");;
                  }}
                >
                  Codebook
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step id="step-3">
          <StepLabel>
            Select variables
          </StepLabel>
          <StepContent>
            <div className="text">
              Third, select all of the variables you'd like to include in your table. The variables listed below are the most relevant variables for this unit of observation. However, it is also possible to merge in data from other units of observation. By default, all variables are included. Use the button below to check the codebook if you're not sure which variables you want.
            </div>
            {variablesForm}
            <div className="form-button-row">
              <div className="form-container">
                <Button
                  variant="contained"
                  startIcon={<NextIcon />}
                  onClick={() => {
                    handleNextButton();
                    getPreviewData();
                    getObservations();
                  }}
                >
                  Next
                </Button>
              </div>
              <div className="form-container">
                <Button
                  startIcon={<BackIcon />}
                  onClick={handleBackButton}
                >
                  Back
                </Button>
              </div>
              <div className="form-container">
                <Button
                  variant="text"
                  startIcon={<SelectIcon />}
                  onClick={() => selectAllVariables()}
                >
                  Select
                </Button>
              </div>
              <div className="form-container">
                <Button
                  variant="text"
                  startIcon={<DeselectIcon />}
                  onClick={() => deselectAllVariables()
                  }>
                  Deselect
                </Button>
              </div>
              <div className="form-container">
                <Button
                  variant="text"
                  startIcon={<CodebookIcon />}
                  onClick={() => {
                    setCodebookDialogOpen(true);
                    trackAction("download_tool_open_codebook", "none");;
                  }}
                >
                  Codebook
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step id="step-4">
          <StepLabel>
            Preview selected data
          </StepLabel>
          <StepContent>
            <div className="text">
              You can preview the data to make sure it's what you want before you download it. If it's not, you can go back and modify your selection.
            </div>
            <div className="form-button-row">
              <div className="form-container">
                <Button variant="contained" startIcon={<PreviewIcon />} onClick={() => {
                  setPreviewDialogOpen(true);
                  getObservations();
                }}>
                  Preview
                </Button>
              </div>
              <div className="form-container">
                <Button variant="contained" startIcon={<NextIcon />} onClick={handleNextButton}>
                  Next
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<BackIcon />} onClick={handleBackButton}>
                  Back
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step id="step-6">
          <StepLabel>
            Agree to cite the database
          </StepLabel>
          <StepContent>
            <CitationComponent />
            <div className="form-button-row">
              <div className="form-container">
                <Button variant="contained" startIcon={<AgreeIcon />} onClick={handleNextButton} >
                  Agree
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<BackIcon />} onClick={handleBackButton}>
                  Back
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<CancelIcon />} onClick={handleRestartButton}>
                  Cancel
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step id="step-7">
          <StepLabel>
            Download codebook
          </StepLabel>
          <StepContent>
            <div className="text">
              You can download the codebook for the database as a PDF file. The codebook contains important data notes for researchers, descriptions of each table, and description of each variable.
            </div>
            <div className="form-button-row">
              <div className="form-container">
                <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-Platform-codebook.pdf" download="IUROPA-CJEU-Database-Platform-codebook.pdf">
                  Codebook
                </Button>
              </div>
              <div className="form-container">
                <Button variant="contained" startIcon={<NextIcon />} onClick={() => {
                  handleNextButton();
                  trackAction("download_tool_download_codebook", "none");;
                }}>
                  Next
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<BackIcon />} onClick={handleBackButton}>
                  Back
                </Button>
              </div>
            </div>
          </StepContent>
        </Step>
        <Step id="step-8">
          <StepLabel>
            Download data
          </StepLabel>
          <StepContent>
            <div className="text">
              You can download the data you've selected as a CSV file. This may take a moment if you've selected a large number of observations. Please <a className="link" href="mailto: joshua.fjelstul@arena.uio.no">let us know</a> if you have any questions about the data.
            </div>
            <div className="form-button-row">
              <div className="form-container">
                <Button variant="contained" startIcon={<DownloadIcon />} onClick={handleDownloadButton}>
                  Data
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<BackIcon />} onClick={handleBackButton}>
                  Back
                </Button>
              </div>
              <div className="form-container">
                <Button variant="text" startIcon={<RestartIcon />} onClick={handleRestartButton}>
                  Restart
                </Button>
              </div>
            </div>
            {downloadBar}
          </StepContent>
        </Step>
      </Stepper>
    </div>

  //--------------------------------------------------
  // return page
  //--------------------------------------------------

  return (
    <div className="download-tool-page-container">
      <div className="block block-shaded">
        <div className="title-large">
          IUROPA Download Tool
        </div>
        {/* <div className="title-small">
          Created by Joshua C. Fjelstul, Ph.D.
        </div> */}
      </div>
      <div className="block">
        {/* <div className="tool-message-container">
          <div className="tool-message-box">
            <div className="tool-message-icon-container">
              <InfoIcon className="tool-message-icon" />
            </div>
            <div className="tool-message-text">
              The IUROPA Download Tool is currently in beta. We'll continue to add more advanced search options. Please <a className="link" href="mailto:joshua.fjelstul@arena.uio.no">contact us</a> if you have suggestions or if you discover a bug.
            </div>
          </div>
        </div> */}
        <div className="text">
          The IUROPA Download Tool makes it easy to build and download a custom table using data from the IUROPA CJEU Database Platform. It walks you through the process of selecting a table, applying filters to select observations, and selecting variables. You can preview your table before you download it. You can also download the codebook for the database. If you want more flexibility, you can <Link className="link" to="/cjeu-database-platform#bulk-download">download the entire database</Link>. The IUROPA Download Tool always draws from the most up-to-date version of the IUROPA CJEU Database Platform.
        </div>
        <div className="text">
          If you use <span className="code">R</span>, you may prefer to use the <span className="code">iuropa</span> package to access the data. The <span className="code">iuropa</span> package provides the same functionality as the IUROPA Download Tool. The <span className="code">iuropa</span> package is available <a className="link" target="_blank" href="https://www.github.com/jfjelstul/iuropa">on GitHub</a>.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained"
              onClick={() => {
                handleRestartButton();
                scroll("step-1", -72 - 100);
              }}
            >
              Start
            </Button>
          </div>
        </div>
        {stepper}
        {codebookDialog}
        {previewDialog}
      </div>
      <div className="block block-shaded">
        <div className="title-medium">
          Supplemental tables
        </div>
        <div className="text">
          The IUROPA Download Tool doesn't cover the {code("8")} supplemental tables in the IUROPA CJEU Database Platform. These supplemental tables indicate the unique values of the {code("8")} IUROPA ID variables that don't serve as the primary key of a table in the main database. You can download the supplemental tables as a {code(".zip")} file containing {code(".csv")} files or {code(".RData")} files.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-Platform-supplemental-csv.zip" download="IUROPA-CJEU-Database-Platform-supplemental-csv.zip">
              CSV
            </Button>
          </div>
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-Platform-supplemental-RData.zip" download="IUROPA-CJEU-Database-Platform-supplemental-RData.zip">
              RData
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
