// React components
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// app component
import App from "./app";

// import components
import Scroll from "./component-scroll";

// CSS
import "./index.css";
import "./styles-mui.css";
import "./styles-footer.css";
import "./styles-app.css";
import "./styles-tool-report.css";
import "./styles-tool-download.css";

// styles
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

// theme
import theme from "./theme-mui";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Scroll/>
        <App />
      </StyledEngineProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
