// React components
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// API components
import Axios from "axios";

// MUI components
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InfoIcon from "@mui/icons-material/Info";

async function loginUser(credentials) {
  var url = "https://api.iuropa.pol.gu.se/authenticate";
  // var url = "http://localhost:4000/authenticate";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

export default function Login(props) {

  const { onSubmit } = props;

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [failure, setFailure] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const handleSubmit = async (value) => {
    const credentials = {
      "username": username,
      "password": password
    };
    try {
      const token = await loginUser(credentials);
      setFailure(false);
      onSubmit(token);
      if (location.pathname.includes("authentication")) {
        navigate(-1);
      }
    } catch(error) {
      console.log(error);
      setFailure(true);
    }
  };

  var message = null;
  if (failure) {
    message =
    <div>
      Incorrect username or password. Please try again.
    </div>
  }

  return(
    <div className="page-centered">
      {/* <div className="tool-message-container">
        <div className="tool-message-box">
          <div className="tool-message-icon-container">
            <InfoIcon className="tool-message-icon"/>
          </div>
          <div className="tool-message-text">
            This content has not yet been released publicly. Please log in to continue.
          </div>
        </div>
      </div> */}
      <div className="button-container-centered">
        <TextField
          style={{ width: 275 }}
          variant="filled"
          label="Username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
      </div>
      <div className="button-container-centered">
        <TextField
          style={{ width: 275 }}
          variant="filled"
          label="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          InputProps={{
            endAdornment:
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }}
        />
      </div>
      <div className="button-container-centered">
        <Button variant="contained" onClick={handleSubmit}>Log in</Button>
      </div>
      <div className="tool-message-container" style={{ opacity: failure ? 1 : 0, cursor: failure ? "text" : "default" }}>
        <div className="tool-message-box">
          <div className="tool-message-text">
            Incorrect username or password. Please try again.
          </div>
        </div>
      </div>
    </div>
  )
}
