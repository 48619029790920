// react components
import React, { Componen, useStatet } from "react";

// mui components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// mui icons
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";

import { trackAction } from "./utilities";

export default function FAQComponent (props) {

  var { question, answer } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div className="faq-container">
      <Accordion onChange={() => {
        setOpen(!open);
        trackAction(open ? "close_faq" : "open_faq", question);
      }}>
        <AccordionSummary className="faq-question-container">
          <div>
            {question} <SelectArrow className="faq-expand-icon" style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)", }}/>
          </div>
        </AccordionSummary>
        <AccordionDetails className="faq-answer-container">
          {answer}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
