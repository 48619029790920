// react components
import React from "react";

// mui components
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import WebsiteIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";

import { trackAction } from "./utilities";

export default function Bio (props) {

  var { id, data, photo } = props;

  const [open, setOpen] = React.useState(false);

  if (data === undefined) {
    return null;
  }

  var image = <div className="bio-photo-placeholder"/>;
  if (data.photo !== "") {
    image = <img className="bio-photo" src={data.photo}/>
  }

  var photoComponent =
  <div className="bio-photo-background">
    <div className="bio-photo-container">
      {image}
      <div className="bio-photo-overlay"/>
    </div>
  </div>;

  if (photo === false) {
    photoComponent = null;
  }

  var icon = null;
  var details = null;
  if (data.bio !== "") {
    details=
    <AccordionDetails>
      <div className="bio-details">
        <div className="bio-title">Biography</div>
        <div className="bio-text">{data.bio}</div>
      </div>
    </AccordionDetails>
    icon =
    <SelectArrow className="bio-expand-icon" style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)", }}/>
  }

  return (
    <div id={id} className="bio-container">
      {photoComponent}
      <div className="bio-body">
        <div className="bio-name">{data.name}</div>
        <div className="bio-institution">{data.institution}</div>
        <Accordion onChange={() => {
          setOpen(!open);
          trackAction(open ? "close_bio" : "open_bio", data.name);
        }}>
          <AccordionSummary>
            <div className="bio-text">{data.preview} {icon}</div>
          </AccordionSummary>
          {details}
        </Accordion>
        <div className="bio-links">
          <IconButton className="bio-icon" sx={{ display: data.email === undefined ? "none" : "flex" }} href={data.email} disableRipple={true}>
            <EmailIcon/>
          </IconButton>
          <IconButton className="bio-icon" sx={{ display: data.website === undefined ? "none" : "flex" }} href={data.website} disableRipple={true}>
            <WebsiteIcon/>
          </IconButton>
          <IconButton className="bio-icon" sx={{ display: data.linkedin === undefined ? "none" : "flex" }} href={data.linkedin} disableRipple={true}>
            <LinkedInIcon/>
          </IconButton>
          <IconButton className="bio-icon" sx={{ display: data.github === undefined ? "none" : "flex" }} href={data.github} disableRipple={true}>
            <GitHubIcon/>
          </IconButton>
          <IconButton className="bio-icon" sx={{ display: data.twitter === undefined ? "none" : "flex" }} href={data.twitter} disableRipple={true}>
            <TwitterIcon/>
          </IconButton>
        </div>
      </div>
    </div>
  );
};
