// react components
import React, { useEffect } from "react"
import { HashLink as Link } from "react-router-hash-link";

// mui components
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/MenuRounded";

// components
import { scroll }  from "./utilities";

export default function Sidebar (props) {

  const { data } = props;

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  var drawer = null;
  if (data !== undefined && data !== null) {
    drawer =
    <div>
      <List>
        {data.map((item, i) => (
          <div key={i}>
            <ListItem className={item.level == 1 ? "sidebar-section-heading" : "sidebar-subsection-heading"} key={i} button onClick={() => {scroll(item.id, 0)}}>
              <ListItemText className={item.level == 1 ? "sidebar-section-text" : "sidebar-subsection-text"} disableTypography={true}>
                {item.label}
              </ListItemText>
            </ListItem>
          </div>
        ))}
      </List>
    </div>
  }

  return(
    <div>
      <Fab className="action-button" onClick={handleDrawerToggle}>
        <MenuIcon />
      </Fab>
      <div>
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          BackdropProps={{
            style: {
              backgroundColor: "rgba(63, 71, 81, 0.3)"
            }
          }}
        >
          {drawer}
        </Drawer>
      </div>
    </div>
  );
};
