// React components
import React, { Component } from 'react'
import { Link } from "react-router-dom";

// mui components
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import DownloadIcon from '@mui/icons-material/CloudDownload';
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import SearchToolIcon from "@mui/icons-material/FindInPage";
import DownloadToolIcon from "@mui/icons-material/PivotTableChart";
import NetworkIcon from "@mui/icons-material/Hub";

// components
import ParallaxBlock from "./component-parallax-block";
import CitationComponent from "./component-citation";
import FAQComponent from "./component-faq";
import { datasets } from "./data-tables";
import { makeCard } from "./utilities";
import { code } from "./utilities";
import { bold } from "./utilities";
import { trackAction } from "./utilities";
import Bio from "./component-bio";
import { bios } from "./data-bios";

export default function HomePage(props) {

  const [selectedDataset, setSelectedDataset] = React.useState("");
  const [version, setVersion] = React.useState("v-0-1");
  const [dataDialogOpen, setDataDialogOpen] = React.useState(false);
  const [codebookDialogOpen, setCodebookDialogOpen] = React.useState(false);

  const handleClick = (dataset) => {
    setSelectedDataset(dataset);
    setDataDialogOpen(true);
  };

  var selectedText = null;
  if (selectedDataset !== "all") {
    selectedText =
      <span>
        You've selected the <span className="code">{selectedDataset}</span> dataset.
      </span>;
  }

  const dataDialog =
    <Dialog
      open={dataDialogOpen}
      onClose={() => setDataDialogOpen(false)}
      PaperProps={{
        style: {
          borderRadius: "8px",
          maxWidth: "60vw",
          maxHeight: "80vh"
        }
      }}
      BackdropProps={{
        style: {
          backgroundColor: "rgba(64, 71, 80, 0.3)",
          backdropFilter: "blur(2px)"
        }
      }}
    >
      <DialogTitle>
        Download the Database
      </DialogTitle>
      <DialogContent>
        <CitationComponent />
      </DialogContent>
      <DialogActions>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              href="/data/IUROPA-CJEU-Database-Platform-csv.zip"
              download="IUROPA-CJEU-Database-Platform.zip"
              onClick={() => {
                trackAction("download_database_csv", "none");
              }}
            >
              CSV
            </Button>
          </div>
          <div className="button-container-centered">
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              href="/data/IUROPA-CJEU-Database-Platform-RData.zip"
              download="IUROPA-CJEU-Database-Platform.zip"
              onClick={() => {
                trackAction("download_database_rdata", "none");
              }}
            >
              RData
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        The IUROPA <br /> CJEU Database
      </div>
    </div>

  return (
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-7.png" content={parallax} offset={-250} />
      <div className="block block-shaded">
        <div className="title-large">
          CJEU Database Platform
        </div>
      </div>
      <div className="block">
        <div className="tool-message-container">
          <div className="tool-message-box">
            <div className="tool-message-icon-container">
              <InfoIcon className="tool-message-icon" />
            </div>
            <div className="tool-message-text">
              We've just released v2.00.00 of the IUROPA CJEU Database Platform! This version includes new data on parties, observers, procedural events, appeals, and more. Please <a className="link" href="mailto:joshua.fjelstul@arena.uio.no">contact us</a> to provide feedback or to offer suggestions for future versions.
            </div>
          </div>
        </div>
        <div className="title-small">
          By Joshua Fjelstul, Daniel Naurin, Stein Arne Brekke, and Silje Synnøve Lyder Hermansen
        </div>
        <div className="text">
          The IUROPA CJEU Database Platform is the largest and most comprehensive research-oriented database on the Court of Justice of the European Union (CJEU). It contains data on the universe of CJEU cases, proceedings, decisions, and judges. It covers the Court of Justice (CJ), General Court (GC), and Civil Service Tribunal (CST) for the Court's entire history (1952-2023). The data is collected from the the Court's official Registry, InfoCuria (the Court's official database), and EUR-Lex (the EU's official database of legal documents). All of the data has been cleaned and cross-validated and is research-ready.
        </div>
        {/* <div className="text">
          The database currently contains {code("12")} main tables, each with a different unit of observation, and {code("5")} supplemental tables that include additional information about the Court. The first set of tables cover the {code("4")} basic units of observation:
        </div>
        <ul className="bullet-point-container">
          <li className="bullet-point">{code("cases")}: One observation per case</li>
          <li className="bullet-point">{code("proceedings")}: One observation per proceeding</li>
          <li className="bullet-point">{code("decisions")}: One observation per decision (judgments, orders, AG opinions, etc.)</li>
          <li className="bullet-point">{code("judges")}: One observation per judge (including AGs)</li>
        </ul>
        <div className="text">
          The second set of tables are sub-tables of the {code("decisions")} table. These tables include variables that are specific to certain types of decisions, such as judgments and AG opinions.
        </div>
        <ul className="bullet-point-container">
          <li className="bullet-point">{code("jugdments")}: A sub-table of the {code("decisions")} table, with one observation of judgment</li>
          <li className="bullet-point">{code("opinions")}: A sub-table of the {code("decisions")} table, with one observation per AG opinion</li>
        </ul>
        <div className="text">
          The third set of tables have more complex units of observation:
        </div>
        <ul className="bullet-point-container">
          <li className="bullet-point">{code("appointments")}: One observation per appointment per judge</li>
          <li className="bullet-point">{code("parties")}: One observation per party per proceeding</li>
          <li className="bullet-point">{code("procedures")}: One observation per procedure per judgment</li>
          <li className="bullet-point">{code("assignments")}: One observation per judge per judgment</li>
          <li className="bullet-point">{code("observers")}: One observation per observer per judgment</li>
          <li className="bullet-point">{code("citations")}: One observation per unique citation per judgment</li>
        </ul> */}
      </div>
      <div className="block block-shaded">
        <div className="title-large" variant="h4">
          Frequently Asked Questions
        </div>
        <FAQComponent
          question="What are the options for downloading the data?"
          answer={
            <div>
              <div className="text">
                There are three ways to download data from the IUROPA CJEU Database Platform. First, you can bulk download the entire CJEU Database Platform. Second, you can use the IUROPA Download Tool to download individual tables or to create custom tables. Third, you can use the {code("iuropa")} {code("R")} package.
              </div>
            </div>
          }
        />
        <FAQComponent
          question="What courts does the database cover?"
          answer={
            <div>
              <div className="text">
                The IUROPA CJEU Database Platform covers the three courts that have made up the Court of Justice of the European Union (CJEU): the Court of Justice (CJ), the General Court (GC), and the Civil Service Tribunal (CST). The Court of Justice was established in 1952, the General Court was established in 1989, and the Civil Service Tribunal was established in 2005. The Civil Service Tribunal was disolved in 2016 and its cases were transferred to the General Court.
              </div>
            </div>
          }
        />
        <FAQComponent
          question="What's the difference between cases and proceedings?"
          answer={
            <div>
              <div className="text">
                {bold("What's a case?")} A "case" is a legal action before the Court. When an applicant initiates a new case, the Court assigns a case ID and records the case in the Registry. Each case has a unique case ID, such as {code("C-999/22")}, where the first letter indicates the Court, the first number is the number of the case, and the second number indicates the year the case was lodged. See the codebook for details. The Court treats appeals as new cases and assigns a new case ID.
              </div>
              <div className="text">
                The {code("cases")} table includes one observation per unique case ID that appears in an official source or that should exist because a case ID with a higher case number appears.
              </div>
              <div className="text">
                {bold("What's a proceeding?")} A "proceeding" is a legal action related to one or more cases, but the term can have two meanings. First, a "proceeding" can refer to a legal action within a case that deals with a specific set of legal procedures. In this sense, a proceeding is narrower than a case. Second, a "proceeding" can refer to a set of joined cases. In this sense, a proceeding is broader than a case.
              </div>
              <div className="text">
                Cases often involve multiple legal procedures. The major procedures are: references for a preliminary ruling, actions for annulment, actions for failure to act, actions for failure to fulfill obligations, actions for damages, appeals, and staff cases. There's also a variety of minor procedures, such as applications for interim measures, applications as to costs, and applications for legal aid. The Court generally handles the minor procedures as separate legal actions, in which case there would be multiple "proceedings" within a single case.
              </div>
              <div className="text">
                When there are multiple proceedings in a case, the Court distinguishes between them by adding suffixes to the case ID. We refer to the combination of a case ID and a suffix (if there is one) as a proceeding ID. A case always has a main proceeding, which may or may not have a suffix. Secondary proceedings always have a suffix. The suffix depends on the legal procedure. If a case has multiple legal proceedings involving the same procedure, the Court uses Roman numerals to uniquely identify them. If a proceeding doesn't have a suffix, the case ID and the proceeding ID will be the same. See the {code("summary_proceeding_suffixes")} table for a list of all possible proceeding suffixes.
              </div>
              <div className="text">
                The {code("proceedings")} table includes one observation per unique proceeding ID that appears in an official source or that should exist because a case ID with a higher case number appears.
              </div>
              <div className="text indent">
                {bold("Example:")} An applicant might file an action for annulment (the main proceeding) and then file an application for interim measures (a secondary proceeding) asking the Court for relief while the Court deliberates. If the case ID is {code("C-999/22")}, the proceeding ID for the main proceeding would be {code("C-999/22")} (the same as the case ID) and the proceeding ID for the secondary proceeding would be {code("C-999/22 R")}. Or, an applicant might file an appeal (the main proceeding) and then an application as to costs (a secondary proceeding), to dispute who should pay the costs of litigation. The proceeding ID for the main proceeding would be {code("C-999/22 P")} and the proceeding ID for the secondary proceeding would be {code("C-999/22 P-DEP")}.
              </div>
              <div className="text indent">
                Note that, in the first example, the proceeding ID for the main proceeding doesn't have a suffix, so the case ID and the proceeding ID are the same. In the second example, the main proceeding does have a suffix, so the case ID and the proceeding ID are different.
              </div>
              <div className="text">
                The Court sometimes joins similar cases together. This allows the Court to deliver one decision that applies to multiple cases. A set of joined cases is also called a "proceeding." Since cases always have a main proceeding, it is more precise to say that the Court joins "proceedings" together rather than "cases."
              </div>
              <div className="text">
                When the Court joins proceedings together, the proceeding that was lodged in the Registry first becomes the proceeding of record, and the proceeding ID for the proceeding of record becomes the proceeding ID for the set of joined proceedings. For each proceeding {code("X")}, the {code("list_joined_proceedings")} variable in the {code("proceedings")} table indicates all proceedings that have been joined to {code("X")}, including {code("X")}. If no proceedings have been joined to {code("X")}, then {code("list_joined_proceedings")} will just list {code("X")}. If {code("X")} is joined to {code("Y")}, {code("list_joined_proceedings")} will only list {code("X")}, and not {code("Y")}.
              </div>
              <div className="text indent">
                {bold("Example:")} Suppose cases {code("C-997/22")} and {code("C-999/22")} both have secondary proceedings {code("C-997/22 DEP")} and {code("C-999/22 DEP")}. The {code("proceedings")} table would have four observations: {code("C-997/22")}, {code("C-997/22 DEP")}, {code("C-999/22")}, and {code("C-999/22 DEP")}. The Court could join the main proceedings, {code("C-997/22")} and {code("C-999/22")}, and/or the secondary proceedings, {code("C-997/22 DEP")} and {code("C-999/22 DEP")}. If it joined only the main proceedings, the proceeding ID for the joined proceedings would be {code("C-997/22")}. The {code("list_joined_proceedings")} variable would be coded {code("C-997/22, C-999/22")} for proceeding ID {code("C-997/22")}. For proceeding ID {code("C-999/22")}, {code("list_joined_proceedings")} would be coded {code("C-999/22")}. This is because {code("C-999/22")} was a separate proceeding that was then joined to {code("C-997/22")}. Proceeding ID {code("C-997/22")} is the proceeding ID for both the separate proceeding {code("C-997/22")} and for the set of joined proceedings {code("C-997/22, C-999/22")}.
              </div>
              <div className="text">
                {bold("How do proceedings relate to legal procedures?")} A proceeding always relates to at least one legal procedure, but a proceeding can also involve multiple legal procedures. For example, a proceeding could involve an action for annulment and an action for damages. Some legal procedures require a separate proceeding. For example, applications as to costs create a new proceeding with the suffix {code("DEP")}. See the {code("summary_procedures")} table for more information about how legal procedures relate to proceeding suffixes.
              </div>
            </div>
          }
        />
        <FAQComponent
          question="Why doesn't the database use ECLI numbers as the main way to identify decisions?"
          answer={
            <div>
              <div className="text">
                The IUROPA CJEU Database Platform includes ECLI numbers for all decisions in the {code("decisions")} table, but we can't rely on ECLI numbers because they don't always uniquely identify decisions. Instead we use IUROPA decision IDs and CJEU decision IDs. We create IUROPA decision IDs to uniquely identify decisions. IUROPA decision IDs also provide more informtion about a decision than ECLI numbers. CJEU decision IDs, which are also uniquely identifying, are a combination of the ECLI number and the date of the decision.
              </div>
              <div className="text">
                There are two general problems with ELCI numbers. First, sometimes one ECLI number is assigned to more than one decision. Second, sometimes more than one ECLI number is assigned to one decision. By cross-referencing data from the Registry, InfoCuria, and EUR-Lex, we address both of these problems and are able to construct a complete table of unique decisions. We provide a unique IUROPA decision ID for each decision that indicates the case of record, the type of the decision, and the date of the decision. These are more informative than ECLI numbers, which don't indicate the type or date of a decision and can't be constructed using metadata about a decision.
              </div>
            </div>
          }
        />
        <FAQComponent
          question="What are the different types of CJEU decisions?"
          answer={
            <div>
              <div className="text">
                We use the general term "decision" to refer to any authoritative legal document published by the Court with respect to a proceeding. There are {code("9")} types of decisions: judgments, orders, opinions of the Court (or just "opinions"), decisions (also a specific type of "decision"), seizure orders, third-party proceedings, rulings (there's only one in the database), Advocate General (AG) opinions, and AG views. The {code("decisions")} table includes all of these.
              </div>
            </div>
          }
        />
        <FAQComponent
          question="What's the difference between a panel and a chamber?"
          answer={
            <div>
              <div className="text">
                When one of the courts hears a proceeding, it's not usually the full court. In fact, it's really rare for all of the judges at one of the courts to participate in a proceeding. Usually, it's only a panel of judges. When we talk about a "panel," we mean the set of judges that actually participate in the proceeding and sign the judgment (if there is one).
              </div>
              <div className="text">
                Panels of judges are selected from "chambers," which are standing configurations of judges. The judges on a panel always come from the same chamber as the Judge-Rapporteur, who is the judge resposible for managing the proceeding. The composition of chambers at each court rotates regularly according to a complex system of rules. The various chambers are called "formations" of the court. The possible formations vary by court. See the {code("summary_formations")} table for more information about which formations are possible at each court.
              </div>
              <div className="text">
                The reason we need to make a distinction between "panels" and "chambers" is that more judges can be attached to a chamber than actually participate in a proceeding. For example, a chamber can have five or six judges attached to it, but only three or five judges would participate in a proceeding. Knowing that a case was heard by the Second Chamber and knowing which judges were attached to the Second Chamber when the case was assigned to a chamber isn't always enough to know which judges were on the panel. It's common for judges to be attached to more than one chamber at the same time. It's also very common for judges to substitute for each other, so we often see panels of judges that don't match the "official" chamber assignments at the time.
              </div>
            </div>
          }
        />
        <FAQComponent
          question="What's the difference between the Court of Justice of the European Union (CJEU), the European Court of Justice (ECJ), and the Court of Justice (CJ)?"
          answer={
            <div>
              <div className="text">
                The current name of the EU's court is the Court of Justice of the European Union (CJEU). It's actually two courts: the Court of Justice (CJ) and the General Court (GC). There used to be a Civil Service Tribunal, but it was disolved in 2016.
              </div>
              <div className="text">
                Originally, there was just one court, the European Court of Justice (ECJ), which was established in 1952 by the Treaty of Paris. Then in 1989, the member states created the Court of First Instance to handle some kinds of direct actions and all staff cases. In 2005, the member states created the Civil Service Tribunal to handle staff cases. In 2009, the Treaty of Lisbon restructured these three courts. It created one court, called the Court of Justice of the European Union (CJEU), with three constituent courts. The European Court of Justice was renamed the Court of Justice and the Court of First Instance was renamed the General Court. The Civil Service Tribunal was dissolved in 2016.
              </div>
              <div className="text">
                Despite the name changes, many people still informally call the Court of Justice the ECJ. When we say "the Court" (capitalized), we mean the CJEU, including each of the constituent courts.
              </div>
            </div>
          }
        />
        <FAQComponent
          question="Why don't I see the Court of First Instance in the database?"
          answer={
            <div>
              <div className="text">
                In 2009, the Court of First Instance was renamed the General Court by the Treaty of Lisbon. In the database, we always use the name "General Court."
              </div>
            </div>
          }
        />
        <FAQComponent
          question="What happened to the Civil Service Tribunal?"
          answer={
            <div>
              <div className="text">
                The member states created the Civil Service Tribunal (CST) in 2005 to handle staff cases, which are disputes between the various EU institutions and agencies and their employees. Jurisdiction over staff cases moved from the Court of First Instance (now the General Court) to the new Civil Service Tribunal. In 2016, member states decided to expand the General Court from one judge per member state to two judges per member state, doubling the size of that court. They decided to disolve the Civil Service Tribunal and transfer jurisdiction over staff cases back to the General Court. Some judges on the Civil Service Tribunal moved to the General Court as part of this expansion.
              </div>
            </div>
          }
        />
      </div>
      <div className="block">
        <div className="title-large" variant="h4">
          IUROPA Explorer Tool
        </div>
        <div className="text">
          You can use the IUROPA Explorer Tool to get an overview of the tables in IUROPA CJEU Database Platform, explore how they connect to each other together, and browse the that variables each table includes. We also provide a guide to help you easily and safely merge tables together to create a dataset for year research project.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              className="button"
              variant="contained"
              startIcon={<NetworkIcon />}
              component={Link} to="/explorer-tool"
              onClick={() => {
                trackAction("page_view", "/explorer-tool");
              }}
            >
              Explorer Tool
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="block block-shaded">
        <div className="title-large" variant="h4">
          The IUROPA Report Tool
        </div>
        <div className="text">
          You can use the <span className="bold">IUROPA Report Tool</span> to generate reports on CJEU cases and judgments that summarize the data in the <span className="bold">IUROPA CJEU Database Platform</span>.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              className="button"
              variant="contained"
              startIcon={<SearchToolIcon/>}
              component={Link} to="/cjeu-database/cjeu-database-platform/report-tool"
              onClick={() => {
                trackAction("page_view", "/cjeu-database/cjeu-database-platform/iuropa-report-tool");
              }}
            >
              Report Tool
            </Button>
          </div>
        </div>
      </div> */}
      <div className="block block-dark">
        <div className="title-large white">
          IUROPA Download Tool
        </div>
        <div className="text white">
          You can use the IUROPA Download Tool to select data from the IUROPA CJEU Database Platform to download. The Download Tool walks you through the process of selecting a table, applying filters to select observtions, and selecting variables. This is the way to go if you don't want to download the entire database.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              className="button-white"
              variant="contained"
              startIcon={<DownloadToolIcon />}
              component={Link} to="/download-tool"
              onClick={() => {
                trackAction("page_view", "/download-tool");
              }}
            >
              Download tool
            </Button>
          </div>
        </div>
      </div>
      <div id="bulk-download" className="block">
        <div className="title-large">
          Bulk Download
        </div>
        <div className="text">
          You can bulk download the entire IUROPA CJEU Database Platform using the button below. The database currently include <span className="code">24</span> tables and <span className="code">8</span> supplemental tables. If you just want to download individual datasets or create custom datasets, please use the IUROPA Download Tool.
        </div>
        <div className="text">
          You can download the database as a <span className="code">.zip</span> file containing <span className="code">.csv</span> files or <span className="code">.RData</span> files. The <span className="code">.RData</span> files are compressed and are smaller. The <span className="code">.csv</span> version of the database is <span className="code">54 MB</span>, and the <span className="code">.RData</span> version is <span className="code">39 MB</span>.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} onClick={() => handleClick("all")}>
              Database
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="block">
        <div className="title-large">
          Download Individual Datasets
        </div>
        <div className="text">
          You can also download individual datasets in the CJEU Database Platform separately. These datasets already have case-level, proceeding-level, and decision-level ID variables merged in where relevant. The individual datasets are available in two formats. You can download a <span className="code">.csv</span> file or an <span className="code">.RData</span> file. Select the cards below to download individual datasets.
        </div>
      </div>
      <div className="block block-shaded card-grid-container">
        {datasets.map((datasets, i) => (
          makeCard(datasets, i, 550, 450, () => handleClick(datasets.name, 0))
        ))}
      </div> */}
      <div id="codebook" className="block block-shaded">
        <div className="title-large">
          Codebook
        </div>
        <div className="text">
          The codebook for the IUROPA CJEU Database Platform includes an introducion to using the database, important data notes for researchers, and descriptions of each table and variable. You can download the codebook as a <span className="code">.pdf</span> file using the button below. You can also access the codebook via the IUROPA Explorer Tool, the IUROPA Download Tool, and the <span className="code">iuropa</span> package in <span className="code">R</span>.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              href="/files/IUROPA-CJEU-Database-Platform-codebook.pdf"
              download="IUROPA-CJEU-Database-Platform-codebook.pdf"
              onClick={() => {
                trackAction("download_codebook", "none");
              }}
            >
              Codebook
            </Button>
          </div>
        </div>
      </div>
      <div id="working-paper" className="block">
        <div className="title-large">
          Article
        </div>
        <div className="text">
          The article that introduces the IUROPA CJEU Database Platform, "The CJEU Database Platform: Decisions and Decision-Makers," is published in the Journal of Law and Courts. The article provides an overview of the Rules of Procedure and explains how the database captures important observable activity. It also provides three empirical applications that illustrate how scholars can use the data and that establish new stylized facts about the internal politics of the Court that can motivate new research.
        </div>
        <div className="tool-message-container">
          <div className="tool-message-box">
            <div className="tool-message-text">
              {bold("Abstract.")} <span style={{ paddingRight: "15px" }} /> This article presents the CJEU Database Platform, which provides scholars with an extensive collection of easily accessible, research-ready data on the the universe of cases, decisions, and judges at the Court of Justice of the European Union (CJEU). The CJEU Database Platform provides a foundation for the broader CJEU Database currently being developed by The IUROPA Project, a multidisciplinary group of scholars researching judicial politics in the European Union (EU). In this article, we illustrate how the CJEU Database Platform opens the door to new areas of theoretical and empirical research on judicial politics in the EU.
            </div>
          </div>
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              href="/files/IUROPA-CJEU-Database-Platform-article.pdf"
              download="IUROPA-CJEU-Database-Platform-article.pdf"
              onClick={() => {
                trackAction("download_article", "none");
              }}
            >
              Article
            </Button>
          </div>
        </div>
      </div>
      <div className="block block-centered block-shaded">
        <div className="title-large">
          Contributors
        </div>
        <Bio id="fjelstul" data={bios.fjelstul} />
        <Bio id="naurin" data={bios.naurin} />
        <Bio id="brekke" data={bios.brekke} />
        <Bio id="hermansen" data={bios.hermansen} />
      </div>
      {dataDialog}
    </div>
  );
};
