// React components
import React from "react"
import { Link } from "react-router-dom"

// material-ui components
import Button from "@mui/material/Button";

// components
import ParallaxBlock from "./component-parallax-block";
import Bio from "./component-bio";
import Sidebar from "./component-sidebar";
import { bios } from "./data-bios";

export default function AboutPage(props) {

  const sidebarData = [
    { "id": "about-iuropa", label: "About IUROPA", level: 1 },
    { "id": "research", label: "Research", level: 1 },
    { "id": "project-coordinators", label: "Project Coordinators", level: 1 },
    { "id": "naurin", label: "Daniel Naurin", level: 2 },
    { "id": "lindholm", label: "Johan Lindholm", level: 2 },
    { "id": "sadl", label: "Urška Šadl", level: 2 },
    { "id": "wallerman-ghavanini", label: "Anna Wallerman Ghavanini", level: 2 },
    { "id": "project-managers", label: "Project Managers", level: 1 },
    { "id": "brekke", label: "Stein Arne Brekke", level: 2 },
    { "id": "fjelstul", label: "Joshua Fjelstul", level: 2 },
    { "id": "hermansen", label: "Silje Synnøve Lyder Hermansen", level: 2 },
    { "id": "larsson", label: "Olof Larsson", level: 2 },
    { "id": "ovadek", label: "Michal Ovádek", level: 2 },
    { "id": "pavone", label: "Tommaso Pavone", level: 2 },
    { "id": "schroeder", label: "Philipp Schroeder", level: 2 },
    { "id": "contributors", label: "Contributors", level: 1 },
    { "id": "boulaziz", label: "Louisa Boulaziz", level: 2 },
    { "id": "cheruvu", label: "Sivaram Cheruvu", level: 2 },
    { "id": "derlen", label: "Mattias Derlén", level: 2 },
    { "id": "espelid", label: "Even Espelid", level: 2 },
    { "id": "hofmann", label: "Andreas Hofmann", level: 2 },
    { "id": "kim", label: "Eun Hye Kim", level: 2 },
    { "id": "lopez-zurita", label: "Lucía López Zurita", level: 2 },
    { "id": "mandujano", label: "Mauricio Mandujano Manriquez", level: 2 },
    { "id": "moberg", label: "Andreas Moberg", level: 2 },
    { "id": "nasstrom", label: "Moa Näsström", level: 2 },
    { "id": "nilsson", label: "Isak Nilsson", level: 2 },
    { "id": "stiansen", label: "Øyvind Stiansen", level: 2 },
  ];

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        About IUROPA
      </div>
    </div>

  const content =
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-5.png" content={parallax} offset={-200} />
      <div id="about-iuropa" className="block">
        <div className="text">
          <b>IUROPA</b> (<i>ius</i> + <i>Europe</i>) is a multidisciplinary platform for empirical legal studies of the European Union (EU). It brings together a network of scholars with backgrounds in law and political science who share an interest in research on law and politics related to the Court of Justice of the European Union (CJEU).
        </div>
        <div className="text">
          A key feature of IUROPA is the development of a comprehensive, reliable, and updated database with detailed information on cases, judgements, and actors involved in the judicial processes of the CJEU: The <Link className="link" to="/">IUROPA CJEU Database</Link>.
        </div>
        <div className="text">
          The database currently includes six components, with main data tables on cases, judges, parties, positions, and more, and supplemental tables with additional information useful for researchers. The information has been collected from public sources (EUR-Lex, InfoCuria, and the CJEU Registry), cross-validated, and corrected when necessary. The database include information derived from manual coding and includes reliability reports.
        </div>
        <div className="text">
          IUROPA organizes training workshops for early career researchers on how to work with empirical data on courts. The next workshop takes place in Oslo September 10-13, 2024. For information on how to apply see <a className="link" href="https://www.sv.uio.no/arena/english/research/news-and-events/events/others/2024/iuropa-workshop.html">this page</a>.
        </div>
        <div className="text">
          The IUROPA Project is financed by the Swedish Research Council (2018-04215), the Norwegian Research Council (223274, PluriCourts), and the European University Institute Research Council (2018-2020).
        </div>
        <div className="text">
          For questions and feedback on IUROPA and the CJEU Database please contact <a className="link" href="mailto:daniel.naurin@arena.uio.no">Daniel Naurin</a> or <a className="link" href="mailto:joshua.fjelstul@arena.uio.no">Joshua Fjelstul</a>.
        </div>
      </div>
      <div id="research" className="block block-shaded">
        <div className="title-large">
          Research
        </div>
        <div className="text">
          To learn more about IUROPA and the <Link className="link" to="/CJEU-database">IUROPA CJEU Database</Link>, you can read the article that introduces the <Link className="link" to="/CJEU-database/CJEU-database-platform">IUROPA CJEU Database Platform</Link> by Stein Arne Brekke, Joshua C. Fjelstul, Silje Synnøve Lyder Hermansen, and Daniel Naurin.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" href="files/IUROPA-CJEU-Database-Platform-article.pdf" download="IUROPA-CJEU-Database-Platform-article.pdf">Download article</Button>
          </div>
        </div>
        <div className="title-small">
          Recent papers
        </div>
        <div className="text">
          Data from the IUROPA CJEU Database has been used in the following articles. If you use IUROPA data in a paper, please let us know so we can feature it here.
        </div>
        <div className="text">
          Brekke, Stein Arne. Speaking Law, Whispering Politics: Mechanisms of Resilience in the Court of Justice of the European Union. Florence: European University Institute, 2024. <a className="link" href="https://hdl.handle.net/1814/76659">https://hdl.handle.net/1814/76659</a>.
        </div>
        <div className="text">
          Brekke, Stein Arne, Joshua C. Fjelstul, Silje Synnøve Lyder Hermansen, and Daniel Naurin. “The CJEU Database Platform: Decisions and Decision-Makers.” Journal of Law and Courts, January 30, 2023, 1-22. <a className="link" href="https://doi.org/10.1017/jlc.2022.3">https://doi.org/10.1017/jlc.2022.3</a>.
        </div>
        <div className="text">
          Brekke, Stein Arne, Daniel Naurin, Urška Šadl, and Lucía López-Zurita. “That's an Order! How the Quest for Efficiency Is Transforming Judicial Cooperation in Europe.” JCMS: Journal of Common Market Studies 61, no. 1 (January 2023): 58-75. <a className="link" href="https://doi.org/10.1111/jcms.13346">https://doi.org/10.1111/jcms.13346</a>.
        </div>
        <div className="text">
          Fjelstul, Joshua C, Matthew Gabel, and Clifford J Carrubba. “The Timely Administration of Justice: Using Computational Simulations to Evaluate Institutional Reforms at the CJEU.” Journal of European Public Policy 30, no. 12 (2023): 2643-64. <a className="link" href="https://doi.org/10.1080/13501763.2022.2113115">https://doi.org/10.1080/13501763.2022.2113115</a>.
        </div>
        <div className="text">
          López Zurita, Lucía, and Stein Arne Brekke. “A Spoonful of Sugar: Deference at the Court of Justice.” JCMS: Journal of Common Market Studies. <a className="link" href="https://doi.org/10.1111/jcms.13547">https://doi.org/10.1111/jcms.13547</a>.
        </div>
        <div className="text">
          Manriquez, Mauricio Mandujano, and Tommaso Pavone. “Follow the Leader: The European Commission, the European Court of Justice, and the EU's Rule of Law Revolution.” Journal of European Public Policy, 2024. <a className="link" href="https://doi.org/10.1080/13501763.2024.2336125">https://doi.org/10.1080/13501763.2024.2336125</a>.
        </div>
        <div className="text">
          Šadl, Urška, Lucía López Zurita, Stein Arne Brekke, and Daniel Naurin. “Law and Orders: The Orders of the European Court of Justice as a Window in the Judicial Process and Institutional Transformations.” European Law Open 1, no. 3 (2022): 549-75. <a className="link" href="https://doi.org/10.1017/elo.2022.32">https://doi.org/10.1017/elo.2022.32</a>.
        </div>
        <div className="text">
          Schroeder, Philipp. “Seizing Opportunities: The Determinants of the CJEU's Deference to National Courts.” Journal of European Public Policy ahead-of-print, no. ahead-of-print (2023): 1-24. <a className="link" href="https://doi.org/10.1080/13501763.2023.2208165">https://doi.org/10.1080/13501763.2023.2208165</a>.
        </div>
        <div className="text">
          Schroeder, Philipp, and Johan Lindholm. “From One to Many: Identifying Issues in CJEU Jurisprudence.” Journal of Law and Courts 11, no. 1 (2023): 163-86. <a className="link" href="https://doi.org/10.1086/717421">https://doi.org/10.1086/717421</a>.
        </div>
      </div>
      <div className="block block-dark">
        <div className="title-large text-white">
          Participants
        </div>
        <div className="text text-white">
          The IUROPA CJEU Database is the product of contributions from a network of scholars in law and political science. This section includes bios for each of the coordinators, project managers, and contributors affiliated with IUROPA.
        </div>
        <div className="text text-white">
          IUROPA has benefited from excellent research assistance from Moa Näsström, Johan Arnborg, Aaron Coster, Sebastian Björnberg, Sarah Kaddar, Eun Hye Kim, Isak Nilsson, Alexandra Nouvel, and Irene Otero.
        </div>
      </div>
      <div id="project-coordinators" className="block block-shaded block-centered">
        <div className="title-large">
          Project Coordinators
        </div>
      </div>
      <div className="block block-centered">
        <Bio id="naurin" data={bios.naurin} />
        <Bio id="lindholm" data={bios.lindholm} />
        <Bio id="sadl" data={bios.sadl} />
        <Bio id="wallerman-ghavanini" data={bios.wallermanGhavanini} />
      </div>
      <ParallaxBlock height={"500px"} path="/media/banner-7.png" offset={-100} />
      <div id="project-managers" className="block block-shaded block-centered">
        <div className="title-large">
          Project Managers
        </div>
      </div>
      <div className="block block-centered">
        <Bio id="brekke" data={bios.brekke} />
        <Bio id="fjelstul" data={bios.fjelstul} />
        <Bio id="hermansen" data={bios.hermansen} />
        <Bio id="larsson" data={bios.larsson} />
        <Bio id="ovadek" data={bios.ovadek} />
        <Bio id="pavone" data={bios.pavone} />
        <Bio id="schroeder" data={bios.schroeder} />
      </div>
      <ParallaxBlock height={"500px"} path="/media/banner-7.png" offset={-100} />
      <div id="contributors" className="block block-shaded block-centered">
        <div className="title-large">
          Contributors
        </div>
      </div>
      <div className="block block-centered">
        <Bio id="boulaziz" data={bios.boulaziz} />
        <Bio id="cheruvu" data={bios.cheruvu} />
        <Bio id="derlen" data={bios.derlen} />
        <Bio id="espelid" data={bios.espelid} />
        <Bio id="hofmann" data={bios.hofmann} />
        <Bio id="kim" data={bios.kim} />
        <Bio id="lopez-zurita" data={bios.lopezZurita} />
        <Bio id="mandujano" data={bios.mandujano} />
        <Bio id="moberg" data={bios.moberg} />
        <Bio id="nasstrom" data={bios.nasstrom} />
        <Bio id="nilsson" data={bios.nilsson} />
        <Bio id="stiansen" data={bios.stiansen} />
      </div>
      <ParallaxBlock height={"500px"} path="/media/banner-7.png" offset={-100} />
      {/* <div id="the-web-app" className="block block-dark block-centered">
        <div className="title-large white">
          The IUROPA Web App
        </div>
      </div> */}
      <div className="block">
      <div className="title-large">
          IUROPA Website
        </div>
        <div className="text">
          The IUROPA website was developed by <a className="link" href="http://www.joshuafjelstul.com">Joshua Fjelstul</a>. It uses a number of technologies (<span className="code">MariaDB</span>, <span className="code">Express.js</span>, <span className="code">React.js</span>, <span className="code">Node.js</span>, and <span className="code">Nginx</span>), languages (<span className="code">JavaScript</span>, <span className="code">JSX</span>, <span className="code">HTML</span>, <span className="code">CSS</span>, <span className="code">JSS</span>, and <span className="code">R</span>), and data formats (<span className="code">json</span>, <span className="code">CSV</span>, and <span className="code">RData</span>) to make IUROPA data accessible to researchers, lawyers, and the public.
        </div>
        <div className="text">
          The website has two main components: the frontend (the interactive part) and the backend (the database and API). The frontend and the backend are completely separate, but communicate with each other. We use modern, cutting-edge technologies in our web stack, including <a className="link" href="https://mariadb.com">MariaDB</a> (for the database), <a className="link" href="https://expressjs.com">Express.js</a> (for the API), <a className="link" href="https://reactjs.org">React.js</a> (for the web app), and <a className="link" href="https://nodejs.org/en">Node.js</a> (for the web server).
        </div>
        <div className="title-small">
          The Frontend
        </div>
        <div className="text">
          The frontend is the part of the web app that users interact with. We built the frontend using a <span className="code">JavaScript</span> framework called <a className="link" href="https://reactjs.org">React.js</a>, which was created by Facebook. React lets you build web apps from custom, reusable components written in <span className="code">JavaScript</span> and <span className="code">JSX</span>, which is mix of <span className="code">HTML</span> and <span className="code">JavaScript</span>.
        </div>
        <div className="text">
          We built all of the interactive components (menus, buttons, etc.) using a React-specific component library called <a className="link" href="https://material-ui.com/">Material-UI</a>, which is an implementation of Google’s design language (a cohesive set of design principles), which is called <a className="link" href="https://material.io/design">Material</a>. We use <span className="code">CSS</span> and <span className="code">JSS</span> to style the web app.
        </div>
        <div className="title-small">
          The Backend
        </div>
        <div className="text">
          The backend is the part of the web app that provides the data. The backend has two components: a <span className="code">MariaDB</span> database and a REST API. We use <a className="link" href="https://mariadb.com">MariaDB</a> for the database, which is an open-source version of <span className="code">MySQL</span>. The API allows users to directly query the database. When a user uses the web app to request data from the database, the front end looks at the selections the user has made and constructs a call to the API. An API call is a <span className="code">URL</span> with optional parameters that specify what data the server should pull from our MariaDB database.
        </div>
        <div className="text">
          We wrote the API in <span className="code">JavaScript</span> using <a className="link" href="https://expressjs.com">Express.js</a>, which is a server framework for <a className="link" href="https://nodejs.org/en/">Node.js</a> (an open-source <span className="code">JavaScript</span> runtime environment). The API looks at requests made by users (the URL it gets from the frontend), converts it to an <span className="code">SQL</span> query, and asks our MariaDB database for the data.
        </div>
        <div className="text">
          The API provides the data in <span className="code">json</span> format, which is the standard way of sending data over an <span className="code">HTTP</span> connection, and sends that back to the frontend. The frontend then parses that <span className="code">json</span> data and converts it into an <span className="code">HTML</span> table that the user can interact with in the web app using a combination of <span className="code">JavaScript</span>, <span className="code">HTML</span>, and <span className="code">JSX</span>. The backend can also provide data in <span className="code">CSV</span> format, which the frontend will package up and save into the user's downloads folder.
        </div>
        <div className="text">
          The API provides data to our <span className="code">R</span> package, <span className="code">iuropa</span>, providing another way for researchers to interact with data. The <span className="code">R</span> package is available from the respository <span className="code">jfjelstul/iuropa</span> on <a className="link" href="https://github.com/jfjelstul/iuropa">GitHub</a>.
        </div>
      </div>
    </div>

  return (
    <div>
      <Sidebar data={sidebarData} />
      {content}
    </div>
  );
};
