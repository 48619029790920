// React components
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

const Page = styled.div`
  position: fixed;
  z-index: 5000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
`;

const Content = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  margin-top: 80px;
  margin-bottom: 80px;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const Section = styled.div`
  width: 70vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ParagraphWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 32px;
  padding-right: 32px;
`;

const Divider = styled.div`
  min-height: 1px;
  width: 70vw;
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: calc(32px - 8px);
  margin-bottom: 32px;
`;

const Header = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  padding-left: 48px;
  padding-right: 48px;
`;

const HeaderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Footer = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
`;

const ButtonWrapper = styled.div`
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
`;

const Token = styled.div`
  // border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  cursor: pointer;
`;

const Language = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
  width: calc(100% - 32px);
  padding-left: 32px;
  line-height: 1;
`;

const Document = styled.div`
  font-size: 20px;
  font-weight: 700;
  width: calc(100% - 32px);
  line-height: 1;
`;

const Button = styled.button`
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
`;

const SolidButton = styled(Button)`
  background: black;
  border: 1.5px solid black;
  color: white;
  &:hover {
    border: 1.5px solid rgba(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.8);
  };
`;

const OutlinedButton = styled(Button)`
  background: white;
  border: 1.5px solid black;
  color: black;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  };
`;

const ToggleButton = styled(Button)`
  background: ${props => props.selected ? "black" : "white"};
  border: 1.5px solid black;
  color: ${props => props.selected ? "white" : "black"};
  &:hover {
    background: ${props => props.selected ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.1)"};
    color: ${props => props.selected ? "white" : "black"};
  };
`;

const ButtonDivider = styled.div`
  height: 50px;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin-left: 16px;
  margin-right: 16px;
`;

const ColorCircle = styled.div`
  position: absolute;
  top: -8px;
  left: -8px;
  height: 16px;
  width: 16px;
  border: 1.5px solid rgba(0, 0, 0, 1);
  border-radius: 50px;
`;

const InfoText = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
`;

const CoderFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: calc(100vh - 80px);
  margin-bottom: 24px;
`;

const TextBox = styled.input`
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  color: rgba(0, 0, 0, 1);
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out;
  border-radius: 8px;
  outline: none;
  &:hover {
    border: 1.5px solid rgba(0, 0, 0, 1);
  }
  &:focus {
    border: 1.5px solid rgba(0, 0, 0, 1);
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
`;

const EditorPage = (props) => {

  const [englishTokens, setEnglishTokens] = React.useState(undefined);
  const [frenchTokens, setFrenchTokens] = React.useState(undefined);
  const [englishTags, setEnglishTags] = React.useState(undefined);
  const [frenchTags, setFrenchTags] = React.useState(undefined);
  const [document, setDocument] = React.useState(undefined);
  const [paragraph, setParagraph] = React.useState(undefined);
  const [paragraphId, setParagraphId] = React.useState(undefined);
  const [first, setFirst] = React.useState(true);
  const [lastIndex, setLastIndex] = React.useState(0);
  const [tagType, setTagType] = React.useState("PER");
  const [value, setValue] = React.useState("");
  const [coder, setCoder] = React.useState(undefined);

  const getParagraph = () => {
    setEnglishTokens(undefined);
    setFrenchTokens(undefined);
    Axios({
      method: "get",
      url: "https://api.iuropa.pol.gu.se/ner-training/get-paragraph",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        const data = response.data.results[0];
        const englishTokens = data.english_text.split(" ");
        const frenchTokens = data.french_text.split(" ");
        // let englishTokens = "On 16 February 2012 , the European Commission published a notice of initiation of an anti - dumping proceeding concerning imports of threaded tube or pipe cast fittings , of malleable cast iron, originating in the People ' s Republic of China , Thailand and Indonesia ( OJ 212 C 44 , p . 33 ) .";
        // let frenchTokens = "Le 16 février 2012, la Commission europénne a publié un avis d ' ouverture d ' une procédure antidumping concernant les importations d ' accessoires de tuyauterie filetés , moulés , en fonte malléable , originaires de République populaire de Chine , de Thaïlande et d ' Indonésie ( JO 2012 , C 44 , p . 33 ) .";
        // englishTokens = englishTokens.split(" ");
        // frenchTokens = frenchTokens.split(" ");

        const englishTags = Array.apply(null, Array(englishTokens.length)).map(() => { return "O" });
        const frenchTags = Array.apply(null, Array(frenchTokens.length)).map(() => { return "O" });
        setEnglishTokens(englishTokens);
        setFrenchTokens(frenchTokens);
        setEnglishTags(englishTags);
        setFrenchTags(frenchTags);
        setDocument(data.cjeu_decision_id);
        setParagraph(data.paragraph_number);
        setParagraphId(data.paragraph_id);
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.data.error);
        }
      });
  }

  useEffect(() => {
    getParagraph();
  }, [])


  // "Accept": "application/json",
  // "Access-Control-Allow-Origin": "*",

  const addTags = () => {
    console.log(paragraphId)
    Axios({
      method: "post",
      url: "https://api.iuropa.pol.gu.se/ner-training/add-tags",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        "paragraph_id": paragraphId,
        "english_tags": englishTags.join(" "),
        "french_tags": frenchTags.join(" "),
        "coder": coder,
      }
    })
      .then(response => {
        getParagraph();
      })
      .catch(error => {
        if (error.response) {
          console.log(error.response.data.error);
        }
      });
  }

  const setBackground = (tag) => {

    let color = "rgba(0, 0, 0, 0.03)";

    if (tag === "B-PER") {
      color = "rgba(253, 150, 68, 1)";
    }

    if (tag === "I-PER") {
      color = "rgba(253, 150, 68, 0.3)";
    }

    if (tag === "B-ACT") {
      color = "rgba(38, 222, 129, 1)";
    }

    if (tag === "I-ACT") {
      color = "rgba(38, 222, 129, 0.3)";
    }

    if (tag === "B-DAT") {
      color = "rgba(252, 92, 101, 1)";
    }

    if (tag === "I-DAT") {
      color = "rgba(252, 92, 101, 0.3)";
    }

    if (tag === "B-LOC") {
      color = "rgba(254, 204, 48, 1)";
    }

    if (tag === "I-LOC") {
      color = "rgba(254, 204, 48, 0.3)";
    }

    if (tag === "B-EUC") {
      color = "rgba(69, 170, 242, 1)";
    }

    if (tag === "I-EUC") {
      color = "rgba(69, 170, 242, 0.3)";
    }

    if (tag === "B-MSC") {
      color = "rgba(166, 94, 234, 1)";
    }

    if (tag === "I-MSC") {
      color = "rgba(166, 94, 234, 0.3)";
    }

    if (tag === "B-EXC") {
      color = "rgba(43, 203, 186, 1)";
    }

    if (tag === "I-EXC") {
      color = "rgba(43, 203, 186, 0.3)";
    }

    return color;
  }

  const setColor = (tag) => {

    let color = "rgba(0, 0, 0, 0.5)";

    if (tag === "B-PER") {
      color = "rgba(255, 255, 255, 0.8)";
    }

    if (tag === "I-PER") {
      color = "rgba(253, 150, 68, 1)";
    }

    if (tag === "B-ACT") {
      color = "rgba(255, 255, 255, 0.8)";
    }

    if (tag === "I-ACT") {
      color = "rgba(38, 222, 129, 1)";
    }

    if (tag === "B-DAT") {
      color = "rgba(255, 255, 255, 0.8)";
    }

    if (tag === "I-DAT") {
      color = "rgba(252, 92, 101, 1)";
    }

    if (tag === "B-LOC") {
      color = "rgba(255, 255, 255, 0.8)";
    }

    if (tag === "I-LOC") {
      color = "rgba(254, 204, 48, 1)";
    }

    if (tag === "B-EUC") {
      color = "rgba(255, 255, 255, 0.8)";
    }

    if (tag === "I-EUC") {
      color = "rgba(69, 170, 242, 1)";
    }

    if (tag === "B-MSC") {
      color = "rgba(255, 255, 255, 0.8)";
    }

    if (tag === "I-MSC") {
      color = "rgba(166, 94, 234, 1)";
    }

    if (tag === "B-EXC") {
      color = "rgba(255, 255, 255, 0.8)";
    }

    if (tag === "I-EXC") {
      color = "rgba(43, 203, 186, 1)";
    }

    return color;
  }

  let title;
  let content;

  const onClick = (index, tags, setTags) => {

    let minIndex;
    let maxIndex;

    // if first click
    if (first) {
      setFirst(false);
      minIndex = index;
      maxIndex = index;
      setLastIndex(index);

    // if second click
    } else {
      setFirst(true);
      minIndex = lastIndex + 1;
      maxIndex = index;
      setLastIndex(undefined);
    }

    let newTag;

    // if it's a first click, add a B tag
    if (first) {
      newTag = "B-" + tagType;

    // if it's a second click, add an I tag
    } else {
      newTag = "I-" + tagType;
    }

    // if you click on a tag that's already been coded on a first click
    if (first && tags[index] !== "O") {
      minIndex = index;
      maxIndex = index;
      newTag = "O";
      setFirst(true);
    }

    const newTags = tags.map((tag, i) => {
      if (i >= minIndex && i <= maxIndex) {
        return newTag;
      } else {
        return tag;
      }
    })

    console.log(newTags)
    setTags(newTags)
  }

  if (coder) {
    if (englishTokens && frenchTokens && englishTags && frenchTags) {
      content =
        <Content>
          <Section>
            <Language>
              English
            </Language>
            <ParagraphWrapper>
              {englishTokens.map((token, i) => {
                return (
                  <Token
                    key={i}
                    style={{
                      background: setBackground(englishTags[i]),
                      color: setColor(englishTags[i])
                    }}
                    onClick={() => onClick(i, englishTags, setEnglishTags)}>
                    {englishTokens[i]}
                  </Token>
                );
              })}
            </ParagraphWrapper>
          </Section>
          <Divider />
          <Section>
            <Language>
              French
            </Language>
            <ParagraphWrapper>
              {frenchTokens.map((token, j) => {
                return (
                  <Token
                    key={j}
                    style={{
                      background: setBackground(frenchTags[j]),
                      color: setColor(frenchTags[j]),
                    }}
                    onClick={() => onClick(j, frenchTags, setFrenchTags)}>
                    {frenchTokens[j]}
                  </Token>
                );
              })}
            </ParagraphWrapper>
          </Section>
        </Content>

      title = document + ", Paragraph " + paragraph;
    }
  } else {
    content =
      <CoderFormWrapper>
        <TextBox placeholder="Coder" value={value} onChange={(e) => setValue(e.target.value)} />
        <OutlinedButton
          onClick={() => {
            setCoder(value);
          }}
        >
          Start
        </OutlinedButton>
      </CoderFormWrapper>
  }

  return (
    <Page>
      <Header>
        <InfoText>
          <b>Paragraph:</b> {title}
        </InfoText>
        <HeaderGroup>
          <InfoText style={{ width: 200}}>
            <b>Coder:</b> {coder}
          </InfoText>
          <InfoText style={{ width: 144}}>
            <b>Current code:</b> {tagType}{first ? "-B" : "-I"}
          </InfoText>
        </HeaderGroup>
      </Header>
      {content}
      <Footer>
        <ButtonWrapper>
          <SolidButton
            onClick={() => {
              addTags();
            }}
          >
            Save
          </SolidButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <OutlinedButton
            onClick={() => {
              getParagraph()
            }}
          >
            Skip
          </OutlinedButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <OutlinedButton
            onClick={() => {
              const englishTags = Array.apply(null, Array(englishTokens.length)).map(() => { return "O" });
              const frenchTags = Array.apply(null, Array(frenchTokens.length)).map(() => { return "O" });
              setEnglishTags(englishTags);
              setFrenchTags(frenchTags);
              setFirst(true);
            }}
          >
            Clear
          </OutlinedButton>
        </ButtonWrapper>
        <ButtonDivider />
        <ButtonWrapper>
          <ColorCircle style={{ backgroundColor: "#FD9644" }} />
          <ToggleButton
            selected={tagType === "PER"}
            onClick={() => {
              setTagType("PER");
            }}
          >
            Person
          </ToggleButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <ColorCircle style={{ backgroundColor: "#26DE81" }} />
          <ToggleButton
            selected={tagType === "ACT"}
            onClick={() => {
              setTagType("ACT");
            }}
          >
            Actor
          </ToggleButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <ColorCircle style={{ backgroundColor: "#FC5C65" }} />
          <ToggleButton
            selected={tagType === "DAT"}
            onClick={() => {
              setTagType("DAT");
            }}
          >
            Date
          </ToggleButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <ColorCircle style={{ backgroundColor: "#FED330" }} />
          <ToggleButton
            selected={tagType === "LOC"}
            onClick={() => {
              setTagType("LOC");
            }}
          >
            Location
          </ToggleButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <ColorCircle style={{ backgroundColor: "#45AAF2" }} />
          <ToggleButton
            selected={tagType === "EUC"}
            onClick={() => {
              setTagType("EUC");
            }}
          >
            EU citation
          </ToggleButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <ColorCircle style={{ backgroundColor: "#A65EEA" }} />
          <ToggleButton
            selected={tagType === "MSC"}
            onClick={() => {
              setTagType("MSC");
            }}
          >
            MS citation
          </ToggleButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <ColorCircle style={{ backgroundColor: "#2BCBBA" }} />
          <ToggleButton
            selected={tagType === "EXC"}
            onClick={() => {
              setTagType("EXC");
            }}
          >
            External citation
          </ToggleButton>
        </ButtonWrapper>
      </Footer>
    </Page >
  );
};

export default EditorPage;
