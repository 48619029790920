import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";

// API components
import Axios from "axios";

function scroll (id, offset) {
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
  window.scrollTo({top: y, behavior: 'smooth'});
};

function jump (id, offset) {
  const element = document.getElementById(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
  window.scrollTo({top: y});
};

function link (value, path, sharedClasses) {
  if (path == null) {
    return (
      <span className={sharedClasses.link}>{value}</span>
    );
  } else {
    return (
      <a className={sharedClasses.link} href={path}>{value}</a>
    );
  }
};

function inlineTitle (value, sharedClasses) {
  return (
    <span className={sharedClasses.inlineTitle}>{value}</span>
  );
};

function code (string) {
  return <span className="code">{string}</span>
};

function bold (string) {
  return <span className="bold">{string}</span>
};

function makeCard (item, i, width, height, onClick) {
    return(
      <div key={i} className="card-container">
        <Card sx={{ maxWidth: width + "px !important", height: height + "px !important" }}>
          <CardActionArea onClick={onClick}>
            <CardMedia
              component="img"
              height={200}
              image={"/media/card-" + (i + 1) + ".png"}
            />
            <CardContent>
              <div className="card-title">
                {item.label}
              </div>
              <div className="card-text">
                {item.description}
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    )
  }

  function parseText (string) {
    var newString = string;
    newString = newString.replaceAll("{", "<span class='code'>");
    newString = newString.replaceAll("}", "</span>");
    return(newString);
  }

  function capitalize (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function trackAction (type, value) {
    const getDate = () => {
      var today = new Date().toString();
      return(today);
    }
    const url = "https://api.iuropa.pol.gu.se/CJEU-database-platform/track-action";
    const body = { "type": type, "value": value };
    Axios.post(url, body)
    .then(response => {

    })
    .catch(error => {
      console.log(error);
    });
  };

export { scroll, jump, link, inlineTitle, bold, code, capitalize, parseText, makeCard, trackAction };
