// React components
import React, { Component, useEffect, useRef } from "react";
import { BrowserRouter, Route, Link, useNavigate } from "react-router-dom";

// API components
import Axios from "axios";

// mui components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Pagination from "@mui/material/Pagination";
import Divider from "@mui/material/Divider";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import DownloadIcon from '@mui/icons-material/CloudDownload';
import BookmarkIcon from '@mui/icons-material/BookmarkRounded';

// mui icons
import SaveIcon from "@mui/icons-material/Save";
import MenuIcon from "@mui/icons-material/Menu";
import LockIcon from "@mui/icons-material/Lock";
import AddIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import FlagIcon from "@mui/icons-material/Flag";
import NextIcon from "@mui/icons-material/SkipNext";
import PreviousIcon from "@mui/icons-material/SkipPrevious";
import CancelIcon from "@mui/icons-material/Cancel";
import ResetIcon from "@mui/icons-material/RestartAlt";
import UserIcon from "@mui/icons-material/AccountCircle";
import UploadIcon from "@mui/icons-material/CloudUpload";
import CorrectIcon from "@mui/icons-material/Check";
import CheckedIcon from "@mui/icons-material/ThumbUp";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import InfoIcon from "@mui/icons-material/Info";
import ViewIcon from "@mui/icons-material/Article";
import ClearIcon from "@mui/icons-material/Clear";

// components
import { scroll } from "./utilities";

export default function Page (props) {

  // props
  const { username, token } = props;

  // data from server
  const [dataText, setDataText] = React.useState(undefined);
  const [dataImage, setDataImage] = React.useState(undefined);

  // search form fields
  const [formEcli, setFormEcli] = React.useState("ECLI:EU:C:1954:7");
  const [formLanguage1, setFormLanguage1] = React.useState("French");
  const [formPage, setFormPage] = React.useState("1");
  const [formLanguage2, setFormLanguage2] = React.useState("");
  const [formColumns, setFormColumns] = React.useState("");
  const [formCoded, setFormCoded] = React.useState("");
  const [formPosition, setFormPosition] = React.useState("");

  // parameters
  const [documentMode, setDocumentMode] = React.useState(false);
  const [coded, setCoded] = React.useState(false);
  const [currentText, setCurrentText] = React.useState("");
  const [searchOn, setSearchOn] = React.useState(true);
  const [noResultsOn, setNoResultsOn] = React.useState(false);

  // search result offset
  const offsetRef = useRef(0);

  // headers
  const headers = {
    "Content-Type": "application/json",
    "authorization": token,
  }

  // reset scrolling
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // navigate
  const navigate = useNavigate();

  //--------------------------------------------------
  // API methods
  //--------------------------------------------------

  // const makePageId = () => {
  //   const pageId = formEcli.replace(/:/gi, "_") + "_" + (formLanguage === "English" ? "EN" : "FR") + "_" + formPage;
  //   return pageId;
  // }

  const createUrl1 = (direction) => {

    var url = null;
    var filters = [];

    if (formEcli !== "") {
      filters.push("ecli=" + formEcli);
    }

    // if (formPage !== "") {
    //   filters.push("page=" + formPage);
    // }

    if (formLanguage1 !== "") {
      filters.push("language=" + formLanguage1);
    }

    if (direction !== undefined) {
      offsetRef.current = offsetRef.current + direction;
    }

    filters.push("offset=" + offsetRef.current);

    filters = filters.join("&");

    // url = "https://localhost:4000/CJEU-text-corpus/text-editor?limit=1&" + filters;
    url = "https://api.iuropa.pol.gu.se/CJEU-text-corpus/text-editor?limit=1&" + filters;

    return url;
  }

  const createUrl2 = (direction) => {

    var url = null;
    var filters = [];

    if (formLanguage2 !== "") {
      filters.push("language=" + formLanguage2);
    }

    if (formColumns !== "") {
      filters.push("columns=" + formColumns);
    }

    if (formCoded !== "") {
      filters.push("coded=" + formCoded);
    }

    if (formPosition !== "") {
      if (formPosition === "first") {
        filters.push("first_page=1");
      }
      if (formPosition === "last") {
        filters.push("last_page=1");
      }
      if (formPosition === "middle") {
        filters.push("first_page=0");
        filters.push("last_page=0");
      }
    }

    if (direction !== undefined) {
      offsetRef.current = offsetRef.current + direction;
    }

    filters.push("offset=" + offsetRef.current);

    filters = filters.join("&");

    // url = "https://localhost:4000/CJEU-text-corpus/text-editor?limit=1&" + filters;
    url = "https://api.iuropa.pol.gu.se/CJEU-text-corpus/text-editor?limit=1&" + filters;

    return url;
  }

  const getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    return(today);
  }

  const getData = (url) => {
    setDataText(undefined);
    setDataImage(undefined);
    setCurrentText(undefined);
    setCoded(false);
    console.log(url);
    Axios.get(url, { headers: headers })
    .then(response => {
      if (response.data.results.length > 0) {
        const data = response.data.results[0];
        setDataText(data);
        setCurrentText(data.current_text);
        setCoded(data.coded);
        const url = "https://api.iuropa.pol.gu.se/CJEU-text-corpus/text-editor/images/" + data.image_file;
        console.log("image url: " + url);
        var imageHeaders = {
          "Content-Type": "application/json",
          "Response-Type": "blob",
          "authorization": token,
        }
        return Axios.get(url, { responseType: "blob", headers: headers });
      } else {
        setNoResultsOn(true);
        return null;
      }
    })
    .then(response => {
      if (response !== null) {
        setDataImage(response.data);
      }
    })
    .catch(error => {
      console.log(error);
      navigate("/CJEU-database/authentication");
    });
  };

  const updateData = (pageId, text, coded, next) => {
    const url = "https://api.iuropa.pol.gu.se/CJEU-text-corpus/text-editor?page_id=" + pageId;
    const body = { "text": text, "coded": coded, "username": username, "date_coded": getDate() };
    setDataText(undefined);
    Axios.put(url, body, { headers: headers })
    .then(response => {
      var direction = 0;
      if (documentMode) {
        if (next) {
          direction = 1;
        }
        if (dataText.page === dataText.count_pages) {
          direction = 0;
        }
        getData(createUrl1(direction));
      } else {
        if (next) {
          direction = 1;
        }
        if (formCoded === "0") {
          direction = 0;
        }
        getData(createUrl2(direction));
      }
    })
    .catch(error => {
      console.log(error);
      navigate.push("/CJEU-database/authentication");
    });
  };

  //--------------------------------------------------
  // button methods
  //--------------------------------------------------

  const clickSearchButton1 = () => {
    setDocumentMode(true);
    var url = createUrl1();
    getData(url);
    setSearchOn(false);
  };

  const clickResetFormButton1 = () => {
    setFormEcli("ECLI:EU:C:1954:7");
    setFormLanguage1("French");
    setFormPage("");
  };

  const clickSearchButton2 = () => {
    setDocumentMode(false);
    var url = createUrl2();
    getData(url);
    setSearchOn(false);
  };

  const clickResetFormButton2 = () => {
    setFormLanguage2("");
    setFormColumns("");
    setFormCoded("");
  };

  const clickNewSearchButton = () => {
    offsetRef.current = 0;
    setDataText(undefined);
    setDataImage(undefined);
    setSearchOn(true);
    setNoResultsOn(false);
  }

  const clickSaveButton = () => {
    updateData(dataText.page_id, currentText, dataText.coded, false);
  }

  const clickCodedButton = () => {
    if (dataText.coded) {
     updateData(dataText.page_id, currentText, false, false);
   } else {
     updateData(dataText.page_id, currentText, true, true);
   }
  }

  const clickNextButton = () => {
    setDataText(undefined);
    setDataImage(undefined);
    var url;
    if (documentMode) {
      url = createUrl1(1);
    } else {
      url = createUrl2(1);
    }
    getData(url);
  }

  const clickPreviousButton = () => {
    setDataText(undefined);
    setDataImage(undefined);
    var url;
    if (documentMode) {
      url = createUrl1(-1);
    } else {
      url = createUrl2(-1);
    }
    getData(url);
  }

  const clickResetTextButton = () => {
    setCurrentText(dataText.original_text);
  }

  const clickShowCurrentTextButton = () => {
    setCurrentText(dataText.current_text);
  }

  const clickResetObservationButton = () => {
    updateData(dataText.page_id, dataText.original_text, false, false);
  }

  //--------------------------------------------------
  // search form
  //--------------------------------------------------

  const search =
  <div className="text-editor-search-panel">
    <div className="text-editor-title">
      <div>
        <Typography variant="h4">
          The IUROPA Text Editor
        </Typography>
        <div className="beta">
          [Beta]
        </div>
      </div>
    </div>
    <div className="text-editor-search-form">
      <div className="row-left">
        <div className="button-container">
          <Button variant="text" startIcon={<InfoIcon/>} href="https://github.com/michalovadek/misc/blob/main/ocr_txt_fix.MD">Read coding instructions</Button>
        </div>
      </div>
      <div>
        <Typography style={{ paddingTop: 32 }} variant="h5">
          Document mode: View a specific document
        </Typography>
        <div className="row-left">
          <div className="form-container">
            <TextField
              style={{ width: 200 }}
              variant="filled"
              label="ECLI Number"
              value={formEcli}
              helperText=""
              onChange={(event) => setFormEcli(event.target.value)}/>
          </div>
          <div className="form-container">
            <FormControl fullWidth>
              <InputLabel>Language</InputLabel>
              <Select
                style={{ width: 200 }}
                variant="filled"
                value={formLanguage1}
                IconComponent={SelectArrow}
                onChange={(event) => setFormLanguage1(event.target.value)}
              >
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="English">English</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <div className="form-container">
            <TextField
              style={{ width: 150 }}
              variant="filled"
              label="Page Number"
              value={formPage}
              helperText=""
              onChange={(event) => setFormPage(event.target.value)}/>
          </div> */}
        </div>
        <div className="row-left">
          <div className="button-container">
            <Button variant="contained" startIcon={<ViewIcon/>} onClick={clickSearchButton1}>View</Button>
          </div>
          <div className="button-container">
            <Button variant="text" startIcon={<ResetIcon/>} onClick={clickResetFormButton1}>Reset</Button>
          </div>
        </div>
        <Typography style={{ paddingTop: 32 }} variant="h5">
          Search mode: Search for pages that meeting the following criteria
        </Typography>
        <div className="row-left">
          <div className="form-container">
            <FormControl fullWidth>
              <InputLabel>Language</InputLabel>
              <Select
                style={{ width: 200 }}
                variant="filled"
                value={formLanguage2}
                IconComponent={SelectArrow}
                onChange={(event) => setFormLanguage2(event.target.value)}
              >
                <MenuItem sx={{fontStyle: "italic"}} value={""}>Any</MenuItem>
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="English">English</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="form-container">
            <FormControl fullWidth>
              <InputLabel>Columns</InputLabel>
              <Select
                style={{ width: 275 }}
                variant="filled"
                value={formColumns}
                IconComponent={SelectArrow}
                onChange={(event) => setFormColumns(event.target.value)}
              >
                <MenuItem sx={{fontStyle: "italic"}} value={""}>Either</MenuItem>
                <MenuItem value={"1"}>Pages with columns</MenuItem>
                <MenuItem value={"0"}>Pages without columns</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row-left">
          <div className="form-container">
            <FormControl fullWidth>
              <InputLabel>Position</InputLabel>
              <Select
                style={{ width: 200 }}
                variant="filled"
                value={formPosition}
                IconComponent={SelectArrow}
                onChange={(event) => setFormPosition(event.target.value)}
              >
                <MenuItem sx={{fontStyle: "italic"}} value={""}>Any</MenuItem>
                <MenuItem value={"first"}>First pages</MenuItem>
                <MenuItem value={"last"}>Last pages</MenuItem>
                <MenuItem value={"middle"}>Middle pages</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="form-container">
            <FormControl fullWidth>
              <InputLabel>Coded</InputLabel>
              <Select
                style={{ width: 150 }}
                variant="filled"
                value={formCoded}
                IconComponent={SelectArrow}
                onChange={(event) => setFormCoded(event.target.value)}
              >
                <MenuItem sx={{fontStyle: "italic"}} value={""}>Either</MenuItem>
                <MenuItem value={"1"}>Yes</MenuItem>
                <MenuItem value={"0"}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row-left">
          <div className="button-container">
            <Button variant="contained" startIcon={<SearchIcon/>} onClick={clickSearchButton2}>Search</Button>
          </div>
          <div className="button-container">
            <Button variant="text" startIcon={<ResetIcon/>} onClick={clickResetFormButton2}>Reset</Button>
          </div>
        </div>
      </div>
    </div>
  </div>

  //--------------------------------------------------
  // content
  //--------------------------------------------------

  var results = null;
  if(dataText !== undefined && dataImage !== undefined) {

    // var checkButton = <Button variant="contained" startIcon={<CorrectIcon/>} onClick={clickCorrectButton}>Code as correct</Button>
    // if(dataText.coded) {
    //   checkButton = <Button variant="contained" startIcon={<SaveIcon/>} onClick={clickCorrectButton}>Save coding</Button>
    // }

    var chips = null;
    if (documentMode) {
      chips =
      <div className="text-editor-chip-row">
        <Chip className="text-editor-chip-color" label="Document mode"/>
        <Chip className="text-editor-chip" label={dataText.ecli}/>
        <Chip className="text-editor-chip" label={dataText.language}/>
        <Chip className="text-editor-chip" label={"Page " + dataText.page + " of " + dataText.count_pages}/>
        <Chip className="text-editor-chip" label={"Status: " + (dataText.coded == 1 ? "coded" : "not coded")}/>
        <Chip className="text-editor-chip" label={"Last edited by: " + dataText.username}/>
      </div>
    } else {
      chips =
      <div className="text-editor-chip-row">
        <Chip className="text-editor-chip-color" label={"Search mode"}/>
        <Chip className="text-editor-chip" label={"Result  " + (offsetRef.current + 1)}/>
        <Chip className="text-editor-chip" label={dataText.ecli}/>
        <Chip className="text-editor-chip" label={dataText.language}/>
        <Chip className="text-editor-chip" label={"Page " + dataText.page}/>
        <Chip sx={{ display: coded ? "flex" : "none" }} className="text-editor-chip" label="Coded"/>
        <Chip sx={{ display: dataText.username != "none" ? "flex" : "none" }} className="text-editor-chip" label={"Last edited by: " + dataText.username}/>
      </div>
    }

    results =
    <div>
      <div className="text-editor-image-panel">
        <div className="text-editor-image-container">
          <img className="text-editor-image" src={URL.createObjectURL(dataImage)}/>
        </div>
      </div>
      <div className="text-editor-input-panel">
        <div className="text-editor-input-container">
          <textarea
            className="text-editor-input"
            value={currentText}
            onInput={(e) => {setCurrentText(e.target.value)}}
            spellCheck="false"
          />
        </div>
      </div>
      <div className="text-editor-top-bar">
        {chips}
      </div>
      <div className="text-editor-bottom-bar">
        <div className="text-editor-nav-buttons">
          <div className="text-editor-button-container-left">
            <Button variant="contained" startIcon={<SearchIcon/>} onClick={clickNewSearchButton}>Search</Button>
          </div>
          <div className="text-editor-button-container-left">
            <Button variant="text" startIcon={<PreviousIcon/>} disabled={offsetRef.current === 0 ? true : false} onClick={clickPreviousButton}>Previous</Button>
          </div>
          <div className="text-editor-button-container-end">
            <Button variant="text" endIcon={<NextIcon/>} onClick={clickNextButton} disabled={documentMode && offsetRef.current + 1 == dataText.count_pages ? true : false}>Next</Button>
          </div>
        </div>
        <div style={{ minWidth: 50 }}/>
        <div className="text-editor-action-buttons">
          <div className="text-editor-button-container-end">
            <Button variant="contained" startIcon={dataText.coded ? <ClearIcon/> : <CorrectIcon/>} onClick={clickCodedButton}>{dataText.coded ? "Uncode" : "Code"}</Button>
          </div>
          <div className="text-editor-button-container-right">
            <Button variant="text" startIcon={<SaveIcon/>} onClick={clickSaveButton}>Save</Button>
          </div>
          <div className="text-editor-button-container-right">
            <Button variant="text" startIcon={<UndoIcon/>} disabled={currentText === dataText.original_text ? true : false} onClick={clickResetTextButton}>Revert to raw</Button>
          </div>
          <div className="text-editor-button-container-right">
            <Button variant="text" startIcon={<RedoIcon/>} disabled={currentText === dataText.current_text || dataText.current_text === dataText.original_text ? true : false} onClick={clickShowCurrentTextButton}>Revert to saved</Button>
          </div>
          <div className="text-editor-button-container-right">
            <Button variant="text" startIcon={<ResetIcon/>} disabled={dataText.current_text != dataText.original_text || dataText.coded === 1 ? false : true} onClick={clickResetObservationButton}>Reset</Button>
          </div>
        </div>
      </div>
    </div>
  } else {
    results =
    <div className='text-editor-progress-bar-container'>
      <div className='text-editor-circle-container'>
        <CircularProgress className='text-editor-background-circle' variant='determinate' size={100} thickness={4} value={100} />
      </div>
      <div className='text-editor-circle-container'>
        <CircularProgress disableShrink size={100} thickness={4} />
      </div>
    </div>
  }

  var content = null;
  if (searchOn) {
    content = search;
  } else {
    if (noResultsOn) {
      content =
      <div className="text-editor-no-results-container">
        <div className="text-editor-no-results-text">
          There are no pages that match your search criteria.
        </div>
        <div className="text-editor-button-container-right">
          <Button variant="text" startIcon={<SearchIcon/>} onClick={clickNewSearchButton}>New search</Button>
        </div>
      </div>
    } else {
      content = results;
    }
  }

  var appBar =
  <div className="text-editor-app-bar">
    <Link className="text-editor-branding" to="/home">
      The <span className="text-editor-branding-large">IUROPA</span> Project
    </Link>
    <div className="text-editor-username-container">
      <UserIcon className="text-editor-username-icon"/>
      <div className="text-editor-username">
        {username}
      </div>
    </div>
  </div>

  //--------------------------------------------------
  // return
  //--------------------------------------------------

  return (
    <div>
      {appBar}
      <div className="text-editor-page">
        {content}
      </div>
    </div>
  );
};
