// React components
import React, { Component } from "react";

// Material-UI components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import ArrowDownwardRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

// function descendingComparator (a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// };
//
// function getComparator (order, orderBy) {
//   return order === "desc"
//   ? (a, b) => descendingComparator (a, b, orderBy)
//   : (a, b) => -descendingComparator (a, b, orderBy);
// };
//
// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// };
//
// function CustomHeader(props) {
//
//   const { headerData } = props;
//
//   return(
//     <TableHead>
//       <TableRow>
//         {headerData.map((column, i) => (
//           <TableCell
//             className="header-cell"
//             key={i}
//             align="left">
//             {column}
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// };
//
// function CustomSortableHeader(props) {
//
//   const { headerData, order, orderBy, onRequestSort } = props;
//
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };
//
//   return(
//     <TableHead>
//       <TableRow>
//         {headerData.map((column, i) => (
//           <TableCell
//             className="header-cell"
//             key={i}
//             align="left"
//             sortDirection={orderBy === column.label ? order : false}>
//             <TableSortLabel
//               active={orderBy === column.label}
//               direction={orderBy === column.label ? order : "asc"}
//               IconComponent={ArrowDownwardRoundedIcon}
//               onClick={createSortHandler(column.label)}>
//               {column.label}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// };
//
// function CustomBody(props) {
//
//   const { bodyData } = props;
//
//   return(
//     <TableBody>
//       {bodyData.map((row, i) => (
//         <TableRow key={i}>
//           {Object.values(row).map((cell, j) => (
//             <TableCell className="body-cell" key={j} align="left">{cell === null ? <span className="missing-value">NA</span> : cell}</TableCell>
//           ))}
//         </TableRow>
//       ))}
//     </TableBody>
//   )
// }

export default function DataTableComponent(props) {

  const { bodyData, headerData, width } = props;

  // console.log(headerData)
  //
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(20);
  // const [order, setOrder] = React.useState("asc");
  // const [orderBy, setOrderBy] = React.useState(null);
  //
  // if (data === null) {
  //   return null;
  // }
  //
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  //
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  //
  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };
  //
  // let bodyData = data;
  //
  // if(paginated && sorted) {
  //   bodyData = stableSort(data, getComparator(order, orderBy))
  //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  // }
  //
  // if(paginated && !sorted) {
  //   bodyData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  // }
  //
  // if(!paginated && sorted) {
  //   bodyData = stableSort(data, getComparator(order, orderBy))
  // }
  //
  // if(!paginated && !sorted) {
  //   bodyData = data
  // }
  //
  // let header = null
  //
  // if (sorted) {
  //   header =
  //   <CustomSortableHeader
  //     headerData={headerData}
  //     order={order}
  //     orderBy={orderBy}
  //     onRequestSort={handleRequestSort}
  //   />
  // } else {
  //   header = <CustomHeader headerData={headerData} />
  // }
  //
  // let body = <CustomBody bodyData={bodyData} />
  //
  // let pagination = null
  //
  // if(paginated) {
  //   pagination =
  //   <TablePagination
  //     rowsPerPageOptions={[20]}
  //     component="div"
  //     count={data.length}
  //     rowsPerPage={rowsPerPage}
  //     page={page}
  //     onChangePage={handleChangePage}
  //     onChangeRowsPerPage={handleChangeRowsPerPage}
  //     backIconButtonProps={{disableRipple: true}}
  //     nextIconButtonProps={{disableRipple: true}}
  //   />
  // }

  return (
    <div>
      <TableContainer sx={{ maxHeight: "400px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerData.map((column, i) => (
                <TableCell
                  className="header-cell"
                  key={i}
                  align="left"
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bodyData.map((row, i) => {
              return(
                <TableRow key={i}>
                  {Object.values(row).map((cell, j) => (
                    <TableCell className="body-cell" key={j} align="left">{cell}</TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
