// React components
import React from "react";
import { Link } from "react-router-dom";

// mui components
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import InfoIcon from "@mui/icons-material/Info";

// components
import ParallaxBlock from "./component-parallax-block";
import Bio from "./component-bio";
import { bios } from "./data-bios";

export default function HomePage(props) {

  const parallax =
    <div className="parallax-content">
      <div className="parallax-title">
        The IUROPA <br/> CJEU Database
      </div>
    </div>

  return (
    <div>
      <ParallaxBlock height="50vh" path="/media/banner-7.png" content={parallax} offset={-250} />
      <div className="block block-shaded">
        <div className="title-large">
          Noncompliance Procedures Component
        </div>
      </div>
      <div className="block">
        <div className="tool-message-container">
          <div className="tool-message-box">
            <div className="tool-message-icon-container">
              <InfoIcon className="tool-message-icon" />
            </div>
            <div className="tool-message-text">
              <b>Coming soon!</b> We're about to release v2.00.00 of the IUROPA CJEU Database Platform. This version will include the Noncompliance Component.
            </div>
          </div>
        </div>
        <div className="title-medium">
          By Joshua Fjelstul
        </div>
        <div className="text">
          The Noncompliance Procedures component of the IUROPA CJEU Database includes data on the Commission's infringement procedure and the Commission's state aid procedure. The database includes five tables: infringement cases (2002-2023), decisions by the Commission in infringement cases (2002-2023), state aid cases (1988-2023), decisisions by the Commission in state aid cases (1998-2023), and actions for failure to fulfill obligations at the CJEU (1952-2023).
        </div>
        <div className="text">
          The case-level tables for infringement cases and state aid cases indicate which cases were referred to the CJEU under Article 260 TFEU, for infringement cases, and under Article 108(2) TFEU, for state aid cases. The table of state aid cases also indicates which cases are the subject of actions for annulment, which are heard by the General Court.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" disabled startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-compliance-csv.zip" download="IUROPA-CJEU-Database-compliance-csv.zip">CSV</Button>
          </div>
          <div className="button-container-centered">
            <Button variant="contained" disabled startIcon={<DownloadIcon />} href="/data/IUROPA-CJEU-Database-compliance-RData.zip" download="IUROPA-CJEU-Database-compliance-RData.zip">RData</Button>
          </div>
        </div>
        <div className="title-small">
          Citation
        </div>
        <div className="text">
          If you use data from the Noncompliance Procedures component of the IUROPA CJEU Database, please cite the data using the following citation:
        </div>
        <div className="quote">
          Fjelstul, Joshua. 2024. "The IUROPA CJEU Database: Noncompliance Procedures," in Lindholm, Johan, Daniel Naurin, Urška Šadl, Anna Wallerman Ghavanini, Stein Arne Brekke, Joshua Fjelstul, Silje Synnøve Lyder Hermansen, Olof Larsson, Andreas Moberg, Moa Näsström, Michal Ovádek, Tommaso Pavone, and Philipp Schroeder, <span className="italic">The IUROPA Court of Justice of the European Union (CJEU) Database</span>, IUROPA, https://www.iuropa.pol.gu.se/.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="text" disabled startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-national-courts-citation.bib" download="IUROPA-CJEU-Database-national-courts-citation.bib">
              BibTeX
            </Button>
          </div>
        </div>
      </div>
      <div className="block block-shaded">
        <div className="title-large">
          Contributors
        </div>
        <Bio id="fjelstul" data={bios.fjelstul}/>
      </div>
      {/* <div className="block block-shaded">
        <div className="title-large">
          Documentation
        </div>
        <div className="text">
          You can download the codebook for the <span className="bold">Issues and Positions Component</span> as a <span className="code">.pdf</span>. The codebook includes a description of each variable, including the values that each value can take, and a detailed explanation about how each variable was coded.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-issues-and-positions-codebook.pdf" download="IUROPA-CJEU-Database-issues-and-positions-codebook.pdf">Codebook</Button>
          </div>
        </div>
        <div className="text">
          You can also download a report as a <span className="code">.pdf</span> that discusses the results of reliability checks conducted for hand-coded variables. The report shows a high degree of inter-coder reliability.
        </div>
        <div className="row-centered">
          <div className="button-container-centered">
            <Button variant="contained" startIcon={<DownloadIcon />} href="/files/IUROPA-CJEU-Database-issues-and-positions-reliability-report.pdf" download="IUROPA-CJEU-Database-issues-and-positions-reliability-report.pdf">Report</Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
