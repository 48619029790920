// React components
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "@mui/material/Button";
import BackIcon from "@mui/icons-material/NavigateBefore";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import NetworkGraph from "./component-network-graph";
import data from "./data-entity-relationship-diagram-test.json";
import theme from "./theme";
import { parseText } from "./utilities";

const Page = styled.div`
  position: fixed;
  z-index: 2000;
  top: 80px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 350px;
  right: 0px;
  top: 80px;
  height: 80px;
  background: white;
  border-bottom: 1px solid ${theme.color.line};
  padding-left: 32px;
  padding-right: 32px;
`;

const BottomMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 350px;
  right: 0px;
  bottom: 0px;
  height: 80px;
  background: white;
  border-top: 1px solid ${theme.color.line};
  padding-left: 32px;
  padding-right: 32px;
`;

const Sidebar = styled.div`
  position: fixed;
  top: 80px;
  bottom: 0px;
  left: 0px;
  width: 350px;
  overflow: scroll;
  border-right: 1px solid ${theme.color.line};
`;

const SidebarContentWrapper = styled.div`
  box-sizing: border-box;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
`;

const CodebookWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CodebookVariableEntry = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
`;

const CodebookVariableName = styled.div`
  font-family: Roboto Mono;
  background-color: ${theme.color.background_code};
  border: 1px solid ${theme.color.line_code};
  color: ${theme.color.text_code};
  font-size: 10pt;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  white-space: nowrap;
  margin-bottom: 8px;
`;

const CodebookVariableDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.8;
  color: ${theme.color.text_dark};
  padding-left: 16px;
`;

const CodebookTableEntry = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CodebookVariablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-top: 1px solid ${theme.color.line};
  padding-top: 32px;
  margin-top: 16px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: ${theme.color.primary};
`;

const Heading = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  line-height: 1.5;
  color: ${theme.color.primary};
`;

const TablesHeading = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-left: 32px;
  margin-top: 50px;
  line-height: 1.3;
  color: ${theme.color.primary};
`;


const Text = styled.div`
  font-size: 15px;
  font-weight: 300;
  line-height: 1.7;
  color: ${theme.color.text_dark};
  margin-bottom: 12px;
  margin-top: 12px;
`;

const TablesWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const TableButton = styled.button`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 300;
  color: ${theme.color.text_medium};
  background: ${theme.color.background};
  // background: white;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  margin-top: 4px;
  margin-bottom: 4px;
  cursor: pointer;
  // transition: 0.2s ease-in-out;
  &:hover {
    background: ${theme.color.primary};
    color: white;
    // color: ${theme.color.primary};
  }
`;

const ButtonWrapper = styled.div`
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const GuideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3000;
  left: 0px;
  top: 80px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
`;

const Guide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 70%;
  max-height: 70%;
  background: white;
  border-radius: 8px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  border: 1px solid ${theme.color.line};
  // box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  overflow: scroll;
`;

const GuideButtonWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Code = styled.span`
  font-family: Roboto Mono;
  background-color: ${theme.color.background_code};
  border: 1px solid ${theme.color.line_code};
  color: ${theme.color.text_code};
  font-size: 10pt;
  font-weight: 400;
  border-radius: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 4px;
  padding-right: 4px;
  white-space: nowrap;
  margin-bottom: 8px;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 80px;
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-right: 32px;
  height: 80px;
`;

const LegendText = styled.div`
  font-size: 14px;
  font-weight: 300;
  padding-left: 8px;
  color: ${theme.color.text_dark};
`;

const LegendTable = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #355E95;
`;

const LegendPrimaryKey = styled.div`
  width: 64px;
  height: 24px;
  border-radius: 6px;
  background: #355E95;
  border: 2px solid #355E95;
`;

const LegendForeignKey = styled.div`
  width: 64px;
  height: 24px;
  border-radius: 6px;
  background: ${theme.color.background_primary};
  border: 2px solid #355E95;
`;


const LegendDashedLine = styled.div`
  width: 8px;
  height: 0px;
  margin-left: 4px;
  margin-right: 4px;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
`;

const tablesJson = require("./tables.json");
const variablesJson = require("./variables.json");

const DatabaseDiagramPage = (props) => {

  const [selected, setSelected] = React.useState(undefined);
  const [guideOpen, setGuideOpen] = React.useState(false);

  const divRef = useRef();

  const scrollToElement = () => {
    const {current} = divRef
     if (current !== null){
       current.scrollIntoView()
     }
  }

  var variablesSubset = variablesJson.filter(item => {
    return item.table === selected;
  });

  var tablesSubset = tablesJson.filter(item => {
    return item.table === selected;
  });

  const makeVariableDescription = (variable, i) => {
    const variableDescription =
      <CodebookVariableEntry key={i}>
        <CodebookVariableName>
          {variable.variable}
        </CodebookVariableName>
        <CodebookVariableDescription>
          <div dangerouslySetInnerHTML={{ __html: parseText(variable.description) }} />
        </CodebookVariableDescription>
      </CodebookVariableEntry >
    return variableDescription;
  }

  const makeDatasetDescription = (tablesSubset, variablesSubset) => {
    const datasetDescription =
      <CodebookTableEntry>
        <Heading>
          {tablesSubset[0].label}
        </Heading>
        <Text dangerouslySetInnerHTML={{ __html: parseText(tablesSubset[0].description) }} />
        <CodebookVariablesWrapper>
          {variablesSubset.map((variable, i) => (
            makeVariableDescription(variable, i)
          ))}
        </CodebookVariablesWrapper>
      </CodebookTableEntry>
    return datasetDescription;
  };

  const menu =
    <SidebarContentWrapper ref={divRef}>
      <ButtonWrapper>
        <Button
          variant="text"
          startIcon={<InfoIcon />}
          onClick={() => {
            setGuideOpen(true);
            setSelected(undefined);
          }}
        >
          Guide
        </Button>
      </ButtonWrapper>
      {/* <Text>
        The IUROPA Explorer Tool is an interactive network graph that shows how the tables in the database are related and how they can be safely merged together.
      </Text> */}
      <Heading>
        Tables
      </Heading>
      <TablesWrapper>
        {tablesJson.map((item, i) => (
          <TableButton onClick={() => {
            setSelected(item.table);
            scrollToElement();
          }}>
            {item.table_label}
          </TableButton>
        ))}
      </TablesWrapper>
    </SidebarContentWrapper>

  const guide =
    <SidebarContentWrapper>
      <ButtonWrapper>
        <Button
          variant="text"
          startIcon={<BackIcon />}
          onClick={() => {
            setGuideOpen(false);
            setSelected(undefined);
          }}
        >
          Tables
        </Button>
      </ButtonWrapper>
      <Heading>
        Introduction to the IUROPA Explorer Tool
      </Heading>
      <Text>
        The IUROPA Explorer Tool is an interactive network graph that shows how the tables in the database are related and how they can be safely merged together.
      </Text>
      {/* <GuideButtonWrapper>
        <Button
          variant="text"
          onClick={() => {
            setGuideOpen(false);
          }}
        >
          Get started
        </Button>
      </GuideButtonWrapper> */}
      <Text>
        The IUROPA CJEU Database Platform is a <b>relational database</b> consisting of separate tables that can be merged together. Tables in relational database can have <b>primary keys</b> and <b>foreign keys</b>. Primary keys are ID variables that uniquely identify each row in a table. Foreign keys are ID variables that serve as primary keys in another table. They indicate how observations in different tables are related.
      </Text>
      <Text>
        In some tables, rows are uniquely identified by a combination of foreign keys, rather than a primary key. For example, in the <Code>assignments</Code> table, there's one observation per judge per decision, and observations are uniquely idenitified by the combination of <Code>iuropa_decision_id</Code> and <Code>iuropa_judge_id</Code>, which are both foreign keys.
      </Text>
      <Text>
        In the network graph, circles indicate tables. Solid blue boxes indicate primary keys, and outlined blue boxes indicate foreign keys. Solid blue lines indicate which IDs are included in which tables, and dashed gray lines indicate safe merges.
      </Text>
      <Text>
        When two tables have ID variables that are linked by a dashed line, it's always safe to left-join the table with the primary key into the table with the foreign key. When merging tables, always use the ID variables with the <Code>iuropa_</Code> prefix. Using official ID variables, like CELEX numbers and ECLI numbers, is not reliable.
      </Text>
    </SidebarContentWrapper>

  let sidebar;

  if (!selected && !guideOpen) {
    sidebar = menu;
  }

  if (!selected && guideOpen) {
    sidebar = guide;
  }

  if (selected) {
    sidebar =
      <SidebarContentWrapper>
        <ButtonWrapper>
          <Button
            variant="text"
            startIcon={<BackIcon />}
            onClick={() => {
              setSelected(undefined);
              setGuideOpen(false);
            }}
          >
            Tables
          </Button>
        </ButtonWrapper>
        <CodebookWrapper>
          {makeDatasetDescription(tablesSubset, variablesSubset)}
        </CodebookWrapper>
      </SidebarContentWrapper>
  }

  return (
    <Page>
      <NetworkGraph linksData={data.edges} nodesData={data.nodes} selected={selected} callback={setSelected} />
      <TopMenu>
        <Title>
          Database structure graph
        </Title>
      </TopMenu>
      <BottomMenu>
        <LegendWrapper>
          <LegendItem>
            <LegendTable />
            <LegendText>
              Table
            </LegendText>
          </LegendItem>
          <LegendItem>
            <LegendPrimaryKey />
            <LegendText>
              Primary key
            </LegendText>
          </LegendItem>
          <LegendItem>
            <LegendForeignKey />
            <LegendText>
              Foreign key
            </LegendText>
          </LegendItem>
          <LegendItem>
            <LegendDashedLine />
            <LegendDashedLine />
            <LegendDashedLine />
            <LegendDashedLine />
            <LegendText>
              Safe merge
            </LegendText>
          </LegendItem>
        </LegendWrapper>
      </BottomMenu>
      <Sidebar>
        {sidebar}
      </Sidebar>
    </Page >
  );
};

export default DatabaseDiagramPage;
